import { useState, useLayoutEffect } from "react";
import parse from 'html-react-parser';
import { sanitize } from 'dompurify';
import Cookies from "js-cookie";

export const html = data => parse(sanitize(data));

export const useRefScrollProgress = (inputRef, marginTop = 0, marginEnd = 0) => {
    const ref = inputRef;
  
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(0);
    const [width, setWidth] = useState(0);
  
    useLayoutEffect(() => {
		if (!ref.current) {
			return;
		}
	
		const rect = ref.current.getBoundingClientRect();
		const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		const offsetTop = rect.top + scrollTop;
	
		setStart((offsetTop + marginTop) / document.body.clientHeight);
		setEnd((offsetTop + rect.height + marginEnd) / document.body.clientHeight);
		setWidth(rect.width);
    });
  
    return { ref, start, end, width };
}

export const getCookieAuth = (cookey) => {
	let ck = Cookies.get("axeptio_cookies");

	if(ck) {
		let parsed = JSON.parse(ck);

		if(parsed["$$completed"] === true && parsed[cookey] === true) {
			return true;
		}
	}

	return false;
}

export const FADE_DURATION = 1.25;
export const FADE_OFFSET = window.innerHeight/1.75;