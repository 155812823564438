import { useEffect, useState } from 'react';
import './popupVimeo.scss';
import CloseIcon from '@mui/icons-material/Close';
import { getCookieAuth } from '../../helpers';

export const PopupVimeo = ({ url = "", handleClose = () => {} }) => {

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        })
    }, []);

    const getDimensions = () => {
        let w = windowWidth;
        let h = (windowWidth * 0.8) / (16/9);

        const maxHeight = windowHeight * 0.9;

        // maxHeight 90vh
        if(h > maxHeight) {
            w = maxHeight * (16/9);
            h = maxHeight;
        }

        return {
            width: w,
            height: h
        }
    }

    return (
        <div className={`popupVimeo`} onClick={handleClose}>

            <iframe
                src={`${url.includes("http") ? "" : "https://player.vimeo.com/video/"}${url}${
                    getCookieAuth("vimeo") ?
                        ""
                    : 
                        url.includes("?") ? "&dnt=1" : "?dnt=1"
                }?autoplay=1`}
                // style={WW < windowHeight ? {
                //     position: 'absolute',
                //     top: 0,
                //     left: 0,
                //     width: "100%",
                //     height: "100%"
                // } : {
                //     width: "100%",
                //     height: windowHeight - 75
                // }}
                style={getDimensions()}
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                className='player'
            ></iframe>

            <div id="closeVideo"><CloseIcon fontSize="large" /></div>

        </div>
    )
}