import { forwardRef, useContext } from "react";
import "./infoGroup.scss";
import { Button } from "../../components/UI/Button/Button";
import { StoreContext } from "../../store/store";
import { html } from "../../helpers";
import { Tags } from "./Tags";
import { FadeSection } from "../../components/FadeSection/FadeSection";


export const InfoGroup = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        button = {},
        content = {},
        image = {},
        infoGroupItem = {},
        subTitle = {},
        title = {}
    } = section;
    const { title: btnTitle = {}, status: btnStatus = {}, url: btnUrl = {} } = button;

    const { data: tags = [] } = infoGroupItem;

    const { lang, pageData } = useContext(StoreContext);
    const { page } = pageData ?? {};
    const { entity = {} } = page ?? {};

    return(
        <section
            className={`pageSection infoGroup ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                {({ isInView }) => <>
                    <img src={image.data ? image.data.path : ""} alt={image.data ? image.data.alt : "not found"} className="main-image" />

                    <div className="infos">

                        <h1>{title.data}</h1>
                        {subTitle.data &&
                            <h2>{subTitle.data}</h2>
                        }

                        <div className="tag-list">

                            {tags
                                .filter(el => el[lang] && el[lang].status && el[lang].title.data)
                                .map((el, i) =>
                                    <Tags
                                        key={i}
                                        isInView={isInView}
                                        i={i}
                                    >
                                        {el[lang] && el[lang].title && el[lang].title.data}
                                    </Tags>
                                )
                            }

                        </div>

                        {content.data &&
                            <div className="desc">
                                {html(content.data)}
                            </div>
                        }

                        {btnStatus.data === true &&
                            <Button to={`/${btnUrl.data}${entity && entity.id ? `?entity=${entity.id}` : ""}`}>
                                {btnTitle.data}
                            </Button>
                        }

                    </div>
                </>}

            </FadeSection>

        </section>
    )
})