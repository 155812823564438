import { forwardRef } from "react";
import "./recrutFestiv.scss";
import { Recrut } from "../recrut/Recrut";
import { PrixFestiv } from "../prixFestiv/PrixFestiv";


export const RecrutFestiv = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        titlePrice = {},
        selectPriceItem = {},
        statusFestiv = {},
        statusRecrut = {}
    } = section;

    return(
        <section
            className={`pageSection recrutFestiv ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            {statusRecrut.data === "true" &&
                <Recrut section={section} />
            }

            {selectPriceItem.data && selectPriceItem.data.length > 0 && statusFestiv.data === "true" &&
                <PrixFestiv
                    title={titlePrice.data}
                    items={selectPriceItem.data ?? []}
                />
            }

        </section>
    )
})