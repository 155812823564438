import { forwardRef, useContext, useEffect, useRef, useState } from "react";
import "./login.scss";
import { Button } from "../../components/UI/Button/Button";
import { Link } from "react-router-dom";
import { StoreContext } from "../../store/store";
import { AuthLogin, GetUserInfo } from "../../store/index.service";
import Cookies from "js-cookie";

export const Login = forwardRef((props, ref) => {
  const { lang, isUserLoggedIn, setIsUserLoggedIn } = useContext(StoreContext);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const rememberMeRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const { section } = props;
  const { content = {}, title = {} } = section;

  useEffect(() => {
    if (isUserLoggedIn) {
      window.location.href = `/${lang}`;
    }
  }, [isUserLoggedIn]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const LoginUser = async () => {
    try {
      const emailFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (!emailInputRef.current.value) {
        setError("L'email est obligatoire !");
        emailInputRef.current.classList.add("error-input");
        setTimeout(() => {
          emailInputRef.current.classList.remove("error-input");
          setIsLoading(false);
          setError("");
        }, 1000);
        return;
      } else if (!emailFormat.test(emailInputRef.current.value)) {
        setError("Format d'email incorrect !");
        emailInputRef.current.classList.add("error-input");
        setTimeout(() => {
          emailInputRef.current.classList.remove("error-input");
          setIsLoading(false);
          setError("");
        }, 1000);
        return;
      }

      if (!passwordInputRef.current.value) {
        setError("Le mot de passe est obligatoire !");
        passwordInputRef.current.classList.add("error-input");
        setTimeout(() => {
          passwordInputRef.current.classList.remove("error-input");
          setIsLoading(false);
          setError("");
        }, 1000);
        return;
      }

      const res = await AuthLogin(
        emailInputRef.current.value,
        passwordInputRef.current.value
      );
      if (res.status === 200) {
        const token = res.data.token;
        const userInfo = await GetUserInfo(token);
        if (userInfo.status !== 200) {
          setError(
            "Une erreur s'est produite lors de la connexion. Veuillez réessayer."
          );
          setTimeout(() => {
            setIsLoading(false);
            setError("");
          }, 1000);
          return;
        }
        setError("Connexion Réussis");
        setIsUserLoggedIn(true);
        Cookies.set("token", token, {
          expires: rememberMeRef.current.checked ? 7 : 1,
        });
        const expirationDate = new Date();
        expirationDate.setTime(
          expirationDate.getTime() +
            (rememberMeRef.current.checked ? 7 : 1) * 24 * 60 * 60 * 1000
        );
        Cookies.set("tokenExpiration", expirationDate.toISOString());
        window.location.href = `/${lang}`;
      } else {
        throw new Error();
      }
    } catch (error) {
      setError(
        "Une erreur s'est produite lors de la connexion. Veuillez réessayer."
      );
      setTimeout(() => {
        setIsLoading(false);
        setError("");
      }, 1000);
    }
  };
  console.log(section);
  return (
    <section className="section-login">
      <div className="section-login-content">
        <div className="section-login-content-left">
          <h1>Se connecter</h1>
        </div>
        <div className="section-login-content-right">
          <div className="section-login-content-right-form">
            <h3> {section.title?.data}</h3>

            <p
              className="section-login-content-right-form-desc"
              dangerouslySetInnerHTML={{
                __html: section.content?.data,
              }}
            ></p>
            <input type="email" placeholder="Email" ref={emailInputRef} />
            <div className="password-div">
              <input
                type={showPassword ? "text" : "password"}
                placeholder="Mot de passe"
                ref={passwordInputRef}
              />

              <div
                className="password-toggle"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? (
                  <span class="material-symbols-outlined">visibility_off</span>
                ) : (
                  <span class="material-symbols-outlined">visibility</span>
                )}
              </div>
            </div>

            <div className="section-login-content-right-form-action">
              <div>
                <input type="checkbox" ref={rememberMeRef} />
                <p>Rester connecté</p>
              </div>

              {/* <p>Forgot password ?</p> */}
            </div>
            {isLoading ? (
              <Button>Connexion en cours...</Button>
            ) : error ? (
              <p className="error-message">{error}</p>
            ) : (
              <button className="btn" onClick={() => LoginUser()}>
                Se connecter
              </button>
            )}
            <p className="section-login-content-right-form-create-account">
              Vous n'avez pas de compte ?
              <Link
                to={{
                  pathname: `contact`,
                  search:
                    "?objet=Demande%20création%20de%20compte&message=Bonjour,%20je%20souhaite%20créer%20un%20compte%20sur%20le%20site%20supeRights.",
                }}
              >
                Créer un compte
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
});
