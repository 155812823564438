import "./contactUs.scss";
import { Button } from "../../components/UI/Button/Button";

export const ContactUs = (props) => {
  const { section = {} } = props;
  const { button = {}, title = {} } = section;
  const {
    title: btnTitle = {},
    status: btnStatus = {},
    url: btnUrl = {},
  } = button;

  return (
    <div className="contact-us">
      <div>
        <p>{title.data}</p>

        {btnStatus.data === true && (
          <Button to={`/${btnUrl.data}`}>{btnTitle.data}</Button>
        )}
      </div>
    </div>
  );
};
