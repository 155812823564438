import { forwardRef, useContext } from "react";
import { Link } from "react-router-dom";
import "./teammate.scss";
import { motion } from "framer-motion";
import { StoreContext } from "../../store/store";
import { html, FADE_DURATION, FADE_OFFSET } from "../../helpers";

import iconLinkedin from "../../utils/img/iconLinkedin.svg";


export const Teammate = (props) => {

    const { img, alt = "", title, job,  territoire, entity, linkedinUrl, className = "", text, isLink, noLogo } = props;

    const { lang, menusData } = useContext(StoreContext);
    const { header: headerData = {} } = menusData ?? {};
    const { info = {} } = headerData;
    const { image: logoGroup = {} } = info;

    const viewportOptions = {
        margin: `0px 0px -${FADE_OFFSET}px 0px`,
        once: true
    }

    const DivOrLink = forwardRef((props, ref) => {
        return entity && isLink ?
            <Link {...props} ref={ref}>
                {props.children}
            </Link>
        :
            <div {...props} ref={ref}>
                {props.children}
            </div>
    })

    return (
        <motion.div
            className={`teammate ${className}`}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: FADE_DURATION }}
            viewport={viewportOptions}
        >

            <DivOrLink to={`/${lang}/${entity ? entity.slug : ""}`} className="img-container">

                <img src={img} alt={alt} className='photo' />

                {/* {(entity || text) &&
                    <div className="entity-hover">
                        {entity &&
                            <img src={entity.logo ? entity.logo.path : ""} alt={entity.logo ? entity.logo.path : entity.title} />
                        }
                        {text &&
                            <div className="desc">{html(text)}</div>
                        }
                    </div>
                } */}

                {( text || !noLogo ) &&
                    <div className="entity-hover">
                       <div className="desc">{html(text)}</div>
                    </div>
                }

            </DivOrLink>

            <div className={`infos ${linkedinUrl ? "withLinkedin" : ""}`}>

                <DivOrLink to={`/${lang}/${entity ? entity.slug : ""}`} className="text">
                    <strong>{title}</strong><br/>
                    {job}<br/>
                   <i> {territoire}</i>
                </DivOrLink>

                {linkedinUrl &&
                    <a href={linkedinUrl} target="_blank" className="linkedin-url">
                        <img src={iconLinkedin} alt="LinkedIn" className="iconLinkedin" />
                    </a>
                }

            </div>

        </motion.div>
    )
}