import { forwardRef, useRef, useContext } from "react";
import "./sliderPhotos.scss";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";
import { trad } from "../../utils/trad";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import arrowRight from "../../utils/img/arrow-right.svg";


export const SliderPhotos = forwardRef((props, ref) => {

    const { section, template } = props;
    const { listPhoto = {} } = section;
    const { data: images = [] } = listPhoto;

    const { lang } = useContext(StoreContext);

    const prevRef = useRef();
    const nextRef = useRef();

    const generateGridSlider = () => {

        let slides = [];

        for(let i=0; i < Math.ceil(images.length / 4); i++) {

            slides.push(
                <SwiperSlide key={i}>

                    {images
                        .slice(4*i, 4*(i+1))
                        .map(el => {

                            const { media = {} } = el[lang] ?? {};

                            return (
                                <div key={el.id} style={{ backgroundImage: `url(${media.data ? media.data.path : ""})` }}>
                                </div>
                            )
                        })
                    }

                </SwiperSlide>
            )
        }

        return slides;
    }

    return images.length > 0 && (
        <section
            className={`pageSection sliderPhotos ${template} bg-white`}
            ref={ref}
        >
            <FadeSection>

                <div className="projets-header">

                    <h2>
                        <strong>{trad[lang].photo}</strong>
                    </h2>

                    <div className="arrows-container">

                        <img ref={prevRef} src={arrowRight} alt="<" className="arrow prev" />
                        <img ref={nextRef} src={arrowRight} alt=">" className="arrow next" />

                    </div>

                </div>

                <Swiper
                    modules={[Navigation]}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                >

                    {generateGridSlider()}
                    
                </Swiper>

            </FadeSection>

        </section>
    )
})