import { forwardRef, useContext } from "react";
import "./notFound.scss";
import { motion } from "framer-motion";
import { SplitFlap } from "../../components/SplitFlap/SplitFlap";
import { Button } from "../../components/UI/Button/Button";
import { trad } from "../../utils/trad";
import { StoreContext } from "../../store/store";
import { FADE_DURATION, FADE_OFFSET } from "../../helpers";

export const NotFound = forwardRef((props, ref) => {

    const { section, template } = props;

    const { lang } = useContext(StoreContext);

    return(
        <section
            className={`pageSection notFound ${template}`}
            ref={ref}
        >

            <motion.div
                className="section-container"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: FADE_DURATION }}
                viewport={{ margin: `0px 0px -${FADE_OFFSET}px 0px`, once: true }}
            >

                <h1>ERREUR</h1>

                <SplitFlap value={404} />

                <p>{trad[lang].not_found}</p>

                <Button to={`/`}>
                    {trad[lang].not_found_btn}
                </Button>

            </motion.div>

        </section>
    )
})