import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { StoreContext } from "../../store/store";

export const Lang = (props) => {

    const { listLang = [], menu = [] } = props;
    
    const { pathname, hash } = useLocation();

    const { setLang, lang: currentLang } = useContext(StoreContext);

    const generateLangLink = () => {

        return listLang.map((el, i) => {

            const path = pathname.slice(4, pathname.length);
            const menuRoute = menu.find(m => m[currentLang].slug === path);
            const menuRouteSlug = menuRoute?.[el]?.slug;

            return (
                <Link
                    key={i}
                    to={`/${el}/${menuRouteSlug ?? path}${hash}`} 
                    className={`langElem ${currentLang === el ? 'active' : ''}`} 
                    onClick={() => setLang(el)}
                >
                    {el}
                </Link>
            )
        })
    }
    
    return(
        <div className="langContainer">
            {generateLangLink()}
        </div>
    )
}