import React, { useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import chevronUp from "../../utils/img/chevron-up.svg";
import { StoreContext } from "../../store/store";

export const ListLink = (props) => {

    const { lk, nav, expandable = false, handleExpandMenu = () => {}, isExpanded, i, handleClick = () => {} } = props;

    const { lang } = useContext(StoreContext);

    const expanded = isExpanded === i;

    const GenerateLinks = (linkProps) => {
        if(nav)
            return <NavLink {...linkProps} />
        else
            return <Link {...linkProps} />
    }

    const handleExpand = (value) => {

        handleExpandMenu(value ? i : false);
    }

    const WW = window.innerWidth;
    const isExpandable = expandable && lk.children && lk.children.length > 0;

    return(
        <div
            className="link-list"
            onMouseEnter={() =>  WW > 1024 && isExpandable && handleExpand(true)}
            onMouseLeave={() => WW > 1024 && isExpandable && handleExpand(false)}
        >

            {isExpandable &&
                <img
                    src={chevronUp}
                    className={`chevron ${expanded ? "expanded" : ""}`}
                    onClick={() => handleExpand(!expanded)}
                />
            }

            {lk.externalLink && lk.url ?
                <a href={lk.url} target="_blank" rel="noopener noreferrer">{lk[lang].title}</a>
            :
                <GenerateLinks
                    to={`/${lang}/${lk.pages ? lk.pages.data[lang].slug.data : lk[lang].slug}`}
                    onClick={() => handleClick(`/${lang}/${lk.pages ? lk.pages.data[lang].slug.data : lk[lang].slug}`)}
                >
                    {lk[lang].title}
                </GenerateLinks>
            }

            <AnimatePresence initial={false}>

                {lk.children && ((expandable && expanded) || !expandable) &&

                    <motion.div
                        className="sub-menu"
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={{
                            open: { opacity: 1, height: WW <= 1024 ? "auto" : 72 },
                            collapsed: { opacity: 0, height: 0 }
                        }}
                        transition={{
                            duration: 0.2,
                            ease: "easeOut",
                            opacity: {
                                delay: WW <= 1024 ? 0.2 : 0
                            }
                        }}
                    >
                        {lk.children.map(sub =>
                            sub.externalLink && sub.url ?
                                <a
                                    href={sub.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={sub.id}
                                >
                                    {sub[lang].title}
                                </a>
                            :
                                <Link
                                    to={`/${lang}/${sub.pages ? sub.pages[lang].slug.data : sub[lang].slug}`}
                                    key={sub.id}
                                    onClick={() => handleClick(`/${lang}/${sub.pages ? sub.pages[lang].slug.data : sub[lang].slug}`)}
                                >
                                    {sub[lang].title}
                                </Link>
                        )}
                    </motion.div>
                }

            </AnimatePresence>
            
        </div>
    )
}