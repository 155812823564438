import { forwardRef, useContext } from "react";
import "./actuDetails.scss";
import { Link } from "react-router-dom";
import { html } from "../../helpers";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";
import { Helmet } from "react-helmet";


export const ActuDetails = forwardRef((props, ref) => {

    const { section, template } = props;
    const {
        background = {},
        title = {},
        date = {},
        thematique = {},
        content = {}
    } = section;
    const { data: items = [] } = section["spe-entity"] ?? {};

    const { lang } = useContext(StoreContext);

    return(
        <section
            className={`pageSection actuDetails ${template} bg-white with-img-banner`}
            ref={ref}
        >

            <Helmet htmlAttributes={{ lang: lang }}>
                <title>
                    {title.data} - Superights
                </title>
                <meta name="description" content={content.data} />
            </Helmet>

            <FadeSection>

                <div className="banner" style={{ backgroundImage: `url(${background.data ? background.data.path : ""})` }}>

                    <div className="entites">

                        {Array.isArray(items) && items.map(el =>
                            <Link className="prim-tag" key={el.id} to={`/${el.slug}`}>
                                <img src={el.logo ? el.logo.path : ""} alt={el.logo ? el.logo.alt : el.title} />
                            </Link>
                        )}

                    </div>

                    <h1>
                        {title.data}
                    </h1>

                    <p className="infos">
                        {date.data}<br/>
                        {thematique.data}
                    </p>

                </div>

                <div className="main-content">

                    {html(content.data)}

                </div>

            </FadeSection>


        </section>
    )
})