import { API } from "./config";
import axios from "axios";
import Cookies from "js-cookie";



const authHeader = async (tokenValue) => {
  return new Promise((resolve, reject) => {
    let token = null;

    if (!tokenValue) {
      token = Cookies.get("token");
    } else {
      token = tokenValue;
    }

    if (token) {
      resolve(token);
    } else {
      reject({ response: { status: 401 } });
    }
  });
};

export const AuthLogin = async (username, password) => {
  return await axios.post(`${API.url}auth`, {
    username: username,
    password: password,
  });
};

export const GetUserInfo = async (tokenValue) => {
  return await authHeader(tokenValue).then((token) => {
    return axios
      .create({
        baseURL: `${API.url}${API.baseServer}`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .get(`/connected/user`);
  });
};

export const getPageData = async (path) => {
  path = path.split("?")[0];
  let pathTab = path.substring(1).split("/");
  pathTab.shift();
  const pathname = pathTab.join("/");

  return await axios.get(`${API.url}${API.base}/page/${pathname}`);
};

export const getMenusData = async () => {
  return await axios.get(`${API.url}${API.base}/header`);
};

export const verifyCAPTCHA = async (token) => {
  return await axios.post(`${API.url}${API.base}/captcha`, {
    token,
  });
};

export const sendContact = async (data) => {
  return await axios.post(`${API.url}${API.base}/send`, data);
};
