import { useEffect, useRef } from "react";
import "./videoIntro.scss";
import videoIntro from "../../utils/SUPERIGHTS_ANIM_LOGO.mp4";
import CloseIcon from '@mui/icons-material/Close';


export const VideoIntro = () => {

    const loaderContainer = useRef();

    useEffect(() => {
        loadVideoIntro();
    }, [])

    const loadVideoIntro = () => {

        let loader = document.getElementById("homeLoaderVideo");
    
        // video only when coming from somewhere else (like Google)
        let comingFrom = document.createElement('a');
        comingFrom.href = document.referrer;

        if(document.referrer !== "" && comingFrom.hostname === window.location.hostname)
            removeVideo();

        else {
            document.documentElement.classList.add("menu-open");
            // if(loader)
            //     loader.play();
        }
    }

    const removeVideo = () => {

        if(loaderContainer.current)
            loaderContainer.current.remove();

        document.documentElement.classList.remove("menu-open");
    }

    return (
        <div id="videoLoading" ref={loaderContainer}>

            <video id="homeLoaderVideo" muted src={videoIntro} onEnded={removeVideo} autoPlay playsInline></video>

            <div id="closeVideo" onClick={removeVideo}><CloseIcon fontSize="large" /></div>

        </div>
    )
}