import { useState, useRef, forwardRef, useContext } from "react";
import { Link } from "react-router-dom";
import "./heroHome.scss";
import { Button } from "../../components/UI/Button/Button";
import { StoreContext } from "../../store/store";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade, Controller } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import arrowRight from "../../utils/img/arrow-right.svg";

export const HeroHome = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        sliderHome = {},
        content = {},
        button = {}
    } = section;
    const { data: items = [] } = sliderHome;
    const { title: btnTitle = {}, status: btnStatus = {}, url: btnUrl = {} } = button;

    const [controlledSwiper, setControlledSwiper] = useState();

    const { lang } = useContext(StoreContext);

    const prevRef = useRef();
    const nextRef = useRef();
    const mainSliderRef = useRef();

    const WW = window.innerWidth;

    return(
        <section
            className={`pageSection heroHome ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <div className="hero-footer">

                    <Swiper
                        modules={[EffectFade, Controller]}
                        className="hero-title-slider"
                        effect="fade"
                        allowTouchMove={WW <= 1024 ? true : false}
                        ref={mainSliderRef}
                        onSwiper={setControlledSwiper}
                    >
                        <SwiperSlide>
                        </SwiperSlide>

                        {items.map(el => {

                            const {
                                projet = {}
                            } = el[lang] ?? {};
                            const { data: projData = {} } = projet;
                            const { title = {} } = projData[lang] ?? {};

                            return (
                                <SwiperSlide key={el.id}>
                                    <Link to={`/nos-projets/${title.slug}`}>{title.data}</Link>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>

                    <div className="arrows-container" style={{ display: items && items.length > 0 ? undefined : "none" }}>

                        <img ref={prevRef} src={arrowRight} alt="<" className="arrow prev" />
                        <img ref={nextRef} src={arrowRight} alt=">" className="arrow next" />

                    </div>
                </div>

                <Swiper
                    modules={[Navigation, EffectFade, Controller]}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    controller={{ control: controlledSwiper }}
                    className="hero-slider"
                    effect="fade"
                    fadeEffect={{
                        crossFade: true
                    }}
                    allowTouchMove={WW <= 1024 ? true : false}
                >
                    <SwiperSlide className="text-slide">
                        <h1>
                            <span id="first-line">{section.title?.data}</span>
                            <span id="second-line">{section.subTitle?.data}</span>
                        </h1>

                        <div className="infos">

                            <div className="subtitle">
                                {html(content.data)}
                            </div>

                            {btnStatus.data === true &&
                                <Button to={`/${btnUrl.data}`}>
                                    {btnTitle.data}
                                </Button>
                            }
                            
                        </div>

                    </SwiperSlide>

                    {items.map(el => {

                        const {
                            image = {},
                            projet = {}
                        } = el[lang] ?? {};
                        const { data: projData = {} } = projet;
                        const { title = {} } = projData[lang] ?? {};
                        const { data: entities = [] } = projData[lang] ? projData[lang]["spe-entity"] : {};
                        const { logoSvg = {} } = entities[0] ?? {};

                        return (
                            <SwiperSlide key={el.id}>
                                <Link to={`/${lang}/nos-projets/${title.slug}`}>
                                    <img
                                        src={image.data ? image.data.path : ""}
                                        alt={image.data ? image.data.alt : "not found"}
                                        style={{
                                            "--letter-mask": `url("${logoSvg.path}")`,
                                        }}
                                    />
                                </Link>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

            </FadeSection>

        </section>
    )
})