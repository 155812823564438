import { forwardRef, useContext } from "react";
import "./equipe.scss";
import { Teammate } from "../../components/Teammate/Teammate";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";


export const Equipe = forwardRef((props, ref) => {

    const { section, template, bgColor, noLogo, isLink } = props;
    const {
        title = {},
        subTitle = {},
        teammateItem = {}
    } = section;

    const { data: teammates = [] } = teammateItem;

    const { lang } = useContext(StoreContext);

    return(
        <section
            className={`pageSection equipe ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <h2>
                    <strong>{title.data}</strong>
                    {subTitle.data && <>
                        <br/>
                        {subTitle.data}
                    </>}
                </h2>

                <div className="equipe-list">

                    {teammates
                        .filter(el => el[lang] && el[lang].status && el[lang].status.data)
                        .map(el => {

                            const {
                                firstName = {},
                                lastName = {},
                                post = {},
                                linkedin = {},
                                image = {},
                                introduction = {},
                                territoire = {}
                            } = el[lang] ?? {};

                            return <Teammate
                                img={image.data ? image.data.path : ""}
                                alt={image.data ? image.data.alt : "not found"}
                                title={`${firstName.data} ${lastName.data}`}
                                job={post.data}
                                linkedinUrl={linkedin.data}
                                entity={el[lang] && el[lang]["spe-entity"] && el[lang]["spe-entity"].data ? el[lang]["spe-entity"].data[0] : null}
                                key={el.id}
                                text={introduction.data}
                                territoire={territoire.data}
                                isLink={isLink}
                                noLogo={noLogo}
                            />
                        })
                    }
                    
                </div>

            </FadeSection>

        </section>
    )
})