import { useState, useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Header.scss";
import { StoreContext } from "../../store/store";
import { motion } from "framer-motion";

import { ListLink } from "./ListLink";
import { Lang } from "./Lang";
import { Button } from "../UI/Button/Button";
import Cookies from "js-cookie";

export const Header = () => {
  const { pathname } = useLocation();
  const [isBgWhite, setIsBgWhite] = useState(false);
  const [logoPath, setLogoPath] = useState("");
  const { menusData, pageData, lang, isUserLoggedIn } =
    useContext(StoreContext);
  const { page = {} } = pageData ?? {};
  const { header: headerData = {} } = menusData ?? {};
  const { info = {}, menu = [], langue = {} } = headerData;
  const { image: logo = {}, button = {} } = info;
  const {
    title: btnTitle = {},
    status: btnStatus = {},
    url: btnUrl = {},
  } = button[lang] ?? {};

  const [menuOpen, setMenuOpen] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const headerRef = useRef();

  const handleMenu = (value) => {
    setMenuOpen(value);

    if (!value) setExpanded(false);

    if (value) document.documentElement.classList.add("menu-open");
    else if (!document.getElementById("videoLoading"))
      // don't allow scroll if videoIntro still playing
      document.documentElement.classList.remove("menu-open");
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (headerRef.current && !headerRef.current.contains(event.target))
        setMenuOpen(false);
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [headerRef]);

  useEffect(() => {
    handleMenu(false);
  }, [pageData, pathname]);

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("nav-header");
      if (header) {
        const isWhiteBackground =
          header.classList.contains("bg-white") &&
          !header.classList.contains("transparent-white");
        setIsBgWhite(isWhiteBackground);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!isBgWhite) {
      setLogoPath(info["logo-white"]?.data?.path);
    } else {
      setLogoPath(info.image?.data?.path);
    }
  }, [isBgWhite]);

  const generateLangList = () => {
    let list = [];

    for (const [key, value] of Object.entries(langue)) {
      if (value.data) list.push(key);
    }

    return list;
  };

  const handleClick = (to) => {
    if (to === pathname) {
      handleMenu(false);
      setTimeout(
        () =>
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          }),
        100
      );
    }
  };

  const WW = window.innerWidth;

  const isSafari =
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1;

  const toggleGoLogin = () => {
    window.location.href = `/${lang}/login`;
  };

  const toggleGoLogout = () => {
    const token = Cookies.get("token");
    const tokenExpiration = Cookies.get("tokenExpiration");
    if (token) {
      Cookies.remove("token");
    }
    if (tokenExpiration) {
      Cookies.remove("tokenExpiration");
    }

    window.location.href = `/${lang}/login`;
  };

  return (
    headerData && (
      <motion.header
        id="nav-header"
        className={`${page.slug}`}
        initial="closed"
        animate={menuOpen ? "open" : "closed"}
        variants={{
          closed: {
            backdropFilter: isSafari ? undefined : "blur(0px)",
            height: WW <= 1024 ? 58 : 74,
          },
          open: {
            backdropFilter: isSafari ? undefined : "blur(30px)",
            height: "100vh",
          },
        }}
        ref={headerRef}
        data-open={menuOpen ? "true" : "false"}
        data-safari={isSafari ? "true" : "false"}
        onClick={() => handleMenu(false)}
      >
        <div
          id="headerMain"
          className={menuOpen ? "open" : ""}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="header-container">
            <Link to={`/${lang}`} onClick={() => handleClick(`/${lang}`)}>
              {pathname.endsWith("mentions-legales") ||
              pathname.endsWith("legale-notice") ? (
                <img
                  src={
                    "https://api.superights.net/upload/media/6104a8957e206d8f7e77373a2a4aef5e.png"
                  }
                  alt="Superights"
                  id="logo"
                />
              ) : (
                <img
                  src={!logoPath ? info["logo-white"]?.data?.path : logoPath}
                  alt="Superights"
                  id="logo"
                />
              )}
            </Link>

            <div className="burger-nav">
              <Lang listLang={generateLangList()} menu={menu} />
              {isUserLoggedIn ? (
                <span
                  class="material-symbols-outlined"
                  onClick={toggleGoLogout}
                >
                  logout
                </span>
              ) : (
                <span class="material-symbols-outlined" onClick={toggleGoLogin}>
                  person
                </span>
              )}

              <div
                className={`menuBurger ${menuOpen ? "open" : ""}`}
                onClick={() => handleMenu(!menuOpen)}
              >
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>

        <div
          id="mobileNav"
          className={`${menuOpen ? "open" : ""} ${expanded ? "expanded" : ""}`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="mobile-nav-container">
            <nav>
              {menu &&
                menu.map((lk, i) => (
                  <ListLink
                    lk={lk}
                    key={lk.id}
                    expandable
                    handleExpandMenu={(value) => setExpanded(value)}
                    isExpanded={expanded}
                    i={i}
                    handleClick={handleClick}
                  />
                ))}
            </nav>

            {btnStatus.data === true && (
              <Button
                to={`/${btnUrl.data}`}
                onClick={() => handleClick(`/${lang}/${btnUrl.data}`)}
              >
                {btnTitle.data}
              </Button>
            )}
          </div>
        </div>
      </motion.header>
    )
  );
};
