import { useContext } from "react";
import "./Button.scss";
import { Link } from "react-router-dom";
import { StoreContext } from "../../../store/store";

export const Button = (props) => {

    const { to = "", children, url = null, status = true, onClick = () => {}, state } = props;

    const { lang } = useContext(StoreContext);

    if(url || to.substring(0, 5) === "/http" || to.substring(0, 6) === "/mailto" && status)
        return <a
            href={url ?? to.substring(1)}
            target="_blank"
            rel="noopener noreferrer"
            className={`btn`}
        >
            {children}
        </a>

    return status && 
        <Link
            to={`/${lang}${to}`}
            className={`btn`}
            onClick={onClick}
            state={state}
        >
            {children}
        </Link>
}