import { forwardRef } from "react";
import { getCookieAuth } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const VideoFullscreen = forwardRef((props, ref) => {

    const { section, template } = props;
    const { video = {} } = section;

    return(
        <section
            className={`pageSection videoSection ${template}`}
            ref={ref}
        >

            <FadeSection>


                    <div style={{
                        padding: "28%",
                        position: "relative"
                    }}>
                        <iframe
                            src={`https://player.vimeo.com/video/${video.data}${
                                getCookieAuth("vimeo") ?
                                    video.data && video.data.includes("?") ? "&" : "?"
                                : 
                                    video.data && video.data.includes("?") ? "&dnt=1" : "?dnt=1"
                            }`}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                {/* : null} */}

            </FadeSection>

        </section>
    )
})