import { useEffect, useCallback, useContext, Fragment, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import "./SectionManager.scss";
import { getPageData } from "../../store/index.service";
import { StoreContext } from "../../store/store";
import { trad } from "../../utils/trad";
import { Helmet } from "react-helmet";
import { Loader } from "../Loader/Loader";

import { HeroHome } from "../../sections/heroHome/HeroHome";
import { Metiers } from "../../sections/metiers/Metiers";
import { VideoSection } from "../../sections/videoSection/VideoSection";
import { SuperProjets } from "../../sections/superProjets/SuperProjets";
import { Presentation } from "../../sections/presentation/Presentation";
import { HeroGroup } from "../../sections/heroGroup/HeroGroup";
import { InfoGroup } from "../../sections/infoGroup/InfoGroup";
import { ImgSection } from "../../sections/imgSection/ImgSection";
import { Chiffres } from "../../sections/chiffres/Chiffres";
import { Equipe } from "../../sections/equipe/Equipe";
import { RecrutFestiv } from "../../sections/recrutFestiv/RecrutFestiv";
import { Partenaires } from "../../sections/partenaires/Partenaires";
import { SliderNews } from "../../sections/sliderNews/SliderNews";
import { AutresEntites } from "../../sections/autresEntites/AutresEntites";
import { SliderEquipe } from "../../sections/sliderEquipe/SliderEquipe";
import { Heading } from "../../sections/heading/Heading";
import { TextImgBandeau } from "../../sections/textImgBandeau/TextImgBandeau";
import { ContactForm } from "../../sections/contactForm/ContactForm";
import { Bureaux } from "../../sections/bureaux/Bureaux";
import { ProjetGallery } from "../../sections/projetGallery/ProjetGallery";
import { NewsGallery } from "../../sections/newsGallery/NewsGallery";
import { PrixFestiv } from "../../sections/prixFestiv/PrixFestiv";
import { ProjetDetails } from "../../sections/projetDetails/ProjetDetails";
import { ProjetListInfo } from "../../sections/projetListInfo/ProjetListInfo";
import { SliderCritiques } from "../../sections/sliderCritiques/SliderCritiques";
import { SliderVideos } from "../../sections/sliderVideos/SliderVideos";
import { SliderPhotos } from "../../sections/sliderPhotos/SliderPhotos";
import { ActuDetails } from "../../sections/actuDetails/ActuDetails";
import { ActuSpec } from "../../sections/actuSpec/ActuSpec";
import { SimpleText } from "../../sections/simpleText/SimpleText";
import { NotFound } from "../../sections/notFound/NotFound";
import { ContactUs } from "../../sections/contactUs/ContactUs";
import { Recrut } from "../../sections/recrut/Recrut";
import { ArticleList } from "../../sections/articleList/ArticleList";
import { ArticleRH } from "../../sections/articleRH/ArticleRH";
import { VideoFullscreen } from "../../sections/videoFullscreen/VideoFullscreen";
import { Login } from "../../sections/login/login";
import { SliderMarche } from "../../sections/sliderMarche/SliderMarche";

export const SectionManager = () => {
  const location = useLocation();

  const { locale } = useParams();

  const [loading, setLoading] = useState(true);

  const { setPageData, pageData, lang, setLang, isUserLoggedIn } =
    useContext(StoreContext);

  const { page = {}, sectionPage, listActu } = pageData ?? {};
  const { metaTitle = {}, metaContent = {} } = page[lang] ?? {};

  const ref = useCallback((node) => {
    if (node !== null && node !== undefined) {
      window.scrollTo({
        top: node.offsetTop,
        left: 0,
        behavior: "smooth",
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getPageData(location.pathname)
      .then((res) => {
        setPageData({
          page: res.data.infoPage.data ?? {},
          sectionPage: res.data.page ?? [],
          listActu: res.data.listActu ?? [],
          filters: res.data.filters ?? {},
        });
      })
      .catch((err) => {
        console.error(err);
        setPageData({
          page: { slug: "not-found" },
          sectionPage: [{ template: "notFound" }],
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [location.pathname]);

  useEffect(() => {
    setLang(locale);
  }, [locale]);

  const generateSection = (section = {}, i) => {
    switch (section.template) {
      case "sliderHome":
        return (
          <HeroHome
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
        case "simpleText":
          return (
            <SimpleText
              section={section[lang]}
              bgColor={section.bgColor}
              template={section.template}
              idSection={section.idSection}
              key={i}
              ref={location.hash === `#${section.subTitle}` ? ref : undefined}
            />
          );
      case "metiers":
        return (
          <Metiers
            section={section[lang]}
            metiers={section.entity}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "videoSection":
        return (
          <VideoSection
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "superProjets":
        return (
          <SuperProjets
            section={section[lang]}
            projets={section.projet}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "presentation":
        return (
          <Presentation
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "heroGroup":
        return (
          <HeroGroup
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "infoGroup":
        return (
          <InfoGroup
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "imgSection":
        return (
          <ImgSection
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "chiffres":
        return (
          <Chiffres
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "equipe":
        return (
          <Equipe
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
            isLink
          />
        );
      case "equipeEntity":
        return (
          <Equipe
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
            noLogo
          />
        );
      case "recrutFestiv":
        return (
          <RecrutFestiv
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "partenaires":
        return (
          <Partenaires
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );

      case "sliderNews":
        return (
          <SliderNews
            section={section[lang]}
            listActu={section.actu}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "sliderMarche":
        return (
          <SliderMarche
            section={section[lang]}
            listActu={section.actu}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "autresEntites":
        return (
          <AutresEntites
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "sliderEquipe":
        return (
          <SliderEquipe
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "heading":
      case "headingFilters":
        return (
          <Heading
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "textImgBandeau":
        return (
          <TextImgBandeau
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "contactForm":
        return (
          <ContactForm
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "bureaux":
        return (
          <Bureaux
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "projetGallery":
        return (
          <ProjetGallery
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "newsGallery":
        return (
          <NewsGallery
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "prixFestiv":
        return (
          <PrixFestiv
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
            isSection
          />
        );
      case "projetDetails":
        return (
          <ProjetDetails
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "projetListInfo":
        return (
          <ProjetListInfo
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "sliderCritiques":
        return (
          <SliderCritiques
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "sliderVideos":
        if (isUserLoggedIn) {
          return (
            <SliderVideos
              section={section[lang]}
              bgColor={section.bgColor}
              template={section.template}
              idSection={section.idSection}
              key={i}
              ref={location.hash === `#${section.subTitle}` ? ref : undefined}
            />
          );
        } else {
          return;
        }

      case "sliderPhotos":
        return (
          <SliderPhotos
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "sliderPhotos":
        return (
          <SliderPhotos
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "actu":
        return (
          <>
            <ActuDetails section={section[lang]} />
            {/* <ActuSpec section={section[lang]} /> */}
            <SliderNews
              section={{
                ...section[lang],
                title: { data: trad[lang].related_news_title },
              }}
              listActu={listActu}
            />
          </>
        );
      case "login":
        return (
          <Login
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "notFound":
        return (
          <NotFound
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "contactUs":
        return (
          <ContactUs
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );

      case "recrut":
        return (
          <Recrut
            section={section[lang]}
            isSection
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "projet":
        return (
          <Fragment key={i}>
            <ProjetDetails section={section[lang]} />
            <ProjetListInfo section={section[lang]} />
            <SliderCritiques section={section[lang]} />
            {isUserLoggedIn && <SliderVideos section={section[lang]} />}

            <PrixFestiv
              isSection
              title={trad[lang].prixFestiv_title}
              items={
                section[lang] && section[lang].priceFestiv
                  ? section[lang].priceFestiv.data
                  : []
              }
            />
            <SliderPhotos section={section[lang]} />
          </Fragment>
        );
      case "articleList":
        return (
          <ArticleList
            section={section[lang]}
            isSection
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
      case "articleRH":
        return <ArticleRH section={section[lang]} key={i} />;
      case "videoFullscreen":
        return (
          <VideoFullscreen
            section={section[lang]}
            bgColor={section.bgColor}
            template={section.template}
            idSection={section.idSection}
            key={i}
            ref={location.hash === `#${section.subTitle}` ? ref : undefined}
          />
        );
    }
  };

  return (
    <>
      <Helmet htmlAttributes={{ lang: lang }}>
        <title>{metaTitle.data ?? "Superights"}</title>
        <meta name="description" content={metaContent.data} />
      </Helmet>

      {page && (
        <div id={page.slug} className="page-root">
          {sectionPage && !loading ?
            sectionPage.map((section, i) =>
              generateSection(section, i)
            )
          : (
            <div className="loader-container">
              <Loader
                color="#fff"
                size="50px"
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};
