import { forwardRef } from "react";
import { getCookieAuth } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const VideoSection = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const { video = {} } = section;

    const windowHeight = window.innerHeight;
    const WW = window.innerWidth;

    return(
        <section
            className={`pageSection videoSection ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                {/* {media[0] ? media[0].mimeMedia === "youtube" ?
                    <iframe
                        width={"100%"}
                        height={`${windowHeight}px`}
                        src="https://www.youtube.com/embed/jF5UCTVfHx8?rel=0&enablejsapi=1&modestbranding=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share;"
                        allowFullScreen
                    ></iframe>
                : media[0].mimeMedia === "vimeo" && */}
                    <div style={WW < windowHeight ? {
                        padding: "28%",
                        position: "relative"
                    } : undefined}>
                        <iframe
                            src={`https://player.vimeo.com/video/${video.data}${
                                getCookieAuth("vimeo") ?
                                    video.data && video.data.includes("?") ? "&" : "?"
                                : 
                                    video.data && video.data.includes("?") ? "&dnt=1" : "?dnt=1"
                            }`}
                            style={WW < windowHeight ? {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: "100%",
                                height: "100%"
                            } : {
                                width: "100%",
                                height: windowHeight - 75
                            }}
                            frameBorder="0"
                            allow="autoplay; fullscreen; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                {/* : null} */}

            </FadeSection>

        </section>
    )
})