import { forwardRef, useContext, useState } from "react";
import "./projetDetails.scss";
import { Link } from "react-router-dom";
import { html } from "../../helpers";
import { trad } from "../../utils/trad";
import { StoreContext } from "../../store/store";
import { PopupVimeo } from "../../components/PopupVimeo/PopupVimeo";
import { FadeSection } from "../../components/FadeSection/FadeSection";
import { Helmet } from "react-helmet";


export const ProjetDetails = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        background = {},
        image = {},
        title = {},
        content = {},
        date = {},
        info = {},
        btn_url = {}
    } = section;

    const { data: infoData = [] } = info;
    const { lang } = useContext(StoreContext);
    const [showTrailer, setShowTrailer] = useState(false);
    const DESKTOP = window.innerWidth >= 1024;


    return(
        <section
            className={`pageSection projetDetails ${template} bg-white with-img-banner`}
            ref={ref}
        >

            <Helmet htmlAttributes={{ lang: lang }}>
                <title>
                    {title.data} - Superights
                </title>
                <meta name="description" content={content.data} />
            </Helmet>

            <FadeSection>
                
                <div className="banner" style={{ backgroundImage: `url(${background.data ? background.data.path : ""})` }}>
                    <div className="sous-metiers"></div>
                    <div className="entites"></div>
                </div>

                <div className="main-content">

                    <div className="affiche-container">

                        <img src={image.data ? image.data.path : ""} alt={image.data ? image.data.alt : "not found"} className="affiche" />
                        
                        {!DESKTOP ?
                            btn_url.data &&
                                <div className="btn-container">
                                    <button className="btn" onClick={() => setShowTrailer(true)}>{trad[lang].trailer}</button>
                                </div>
                        :
                            <div className="data-list">

                                {infoData.map(el => {
                                    const {
                                        title = {},
                                        value = {}
                                    } = el[lang] ?? {};

                                    return (
                                        <div className="list-item" key={el.id}>
                                            
                                            <p><strong>{title.data}</strong></p>
                                            <span className="tag">{value.data}</span>

                                    </div>
                                    )
                                })}

                            </div>
                        }

                    </div>

                    <div className="infos">

                        <div className="head">

                            <div className="text">

                                <h1>{title.data}</h1>
                                <p className="subtitle">{date.data}</p>

                            </div>
                        
                            {DESKTOP && btn_url.data &&
                                <button className="btn" onClick={() => setShowTrailer(true)}>{trad[lang].trailer}</button>
                            }

                        </div>

                        <div className="synopsis">

                            <div>
                                <p><b>{trad[lang].synopsis}</b></p>
                                {html(content.data)}
                            </div>

                        </div>

                    </div>

                    {!DESKTOP &&
                        <div className="data-list">

                            {infoData.map(el => {
                                const {
                                    title = {},
                                    value = {}
                                } = el[lang] ?? {};

                                return (
                                    <div className="list-item" key={el.id}>
                                        
                                        <p><strong>{title.data}</strong></p>
                                        <span className="tag">{value.data}</span>

                                    </div>
                                )
                            })}

                        </div>
                    }

                </div>

            </FadeSection>

            {showTrailer &&
                <PopupVimeo
                    url={btn_url.data}
                    handleClose={() => setShowTrailer(false)}
                />
            }

        </section>
    )
})