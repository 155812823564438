import { forwardRef, useContext } from "react";
import "./bureaux.scss";
import { FicheBureau } from "../../components/FicheBureau/FicheBureau";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const Bureaux = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        subTitle = {},
        bureauxItem = {}
    } = section;
    const { data: items = [] } = bureauxItem;

    const { lang } = useContext(StoreContext);

    return(
        <section
            className={`pageSection bureaux ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <h2>
                    <strong>{title.data}</strong>
                    {subTitle.data && <>
                        <br/>
                        {subTitle.data}
                    </>}
                </h2>

                <div className="gallery">

                    {items.map(el => {

                        const {
                            address = {},
                            title = {},
                            image = {},
                            phone = {}
                        } = el[lang] ?? {};

                        return <FicheBureau
                            key={el.id}
                            img={image.data ? image.data.path : ""}
                            alt={image.data ? image.data.alt : "not found"}
                            title={title.data}
                            text={<>{address.data}<br/>{phone.data}</>}
                        />
                    })}

                </div>

            </FadeSection>

        </section>
    )
})