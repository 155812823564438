import { forwardRef, useRef, useContext } from "react";
import "./projetListInfo.scss";
import { motion, useInView } from "framer-motion";
import { StoreContext } from "../../store/store";

export const ProjetListInfo = forwardRef((props, ref) => {

    const { section, template } = props;
    const {
        infoSup = {}
    } = section;
    const { data: infoSupData = [] } = infoSup;

    const { lang } = useContext(StoreContext);

    const windowHeight = window.innerHeight;

    const viewportOptions = {
        margin: `0px 0px -${windowHeight / 2}px 0px`,
        once: true
    }

    const motionRef = useRef();
    const isInView = useInView(motionRef, viewportOptions);

    const variants = {
        visible: { opacity: 1 },
        hidden: { opacity: 0 }
    };

    return(
        <section
            className={`pageSection projetListInfo ${template}`}
            ref={ref}
        >

            <div className="section-container" ref={motionRef}>

                {infoSupData.map((el, i) => {

                    const {
                        title = {},
                        value = {}
                    } = el[lang] ?? {};

                    return (
                        <motion.div
                            className="list-item"
                            key={el.id}
                            variants={variants}
                            initial={"hidden"}
                            animate={isInView ? "visible" : "hidden"}
                            transition={{ duration: 0.8, delay: 0.1*i }}
                        >
                            <p className="title">{title.data}</p>
                            <p>{value.data}</p>
                        </motion.div>
                    )
                })}

            </div>


        </section>
    )
})