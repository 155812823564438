import { forwardRef, useState, useContext, useEffect, useRef } from "react";
import "./sliderVideos.scss";
import { StoreContext } from "../../store/store";
import { trad } from "../../utils/trad";
import axios from "axios";
import { PopupVimeo } from "../../components/PopupVimeo/PopupVimeo";

export const SliderVideos = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { actu = {} } = section;
  const { lang } = useContext(StoreContext);
  const [showTrailer, setShowTrailer] = useState(false);
  const [videoSelect, setVideoSelect] = useState("");
  const containerRef = useRef();

  const Thumbnail = ({ url }) => {
    const [thumbnailUrl, setThumbnailUrl] = useState("");
    const [loading, setLoading] = useState(true);

    const getImage = async () => {
      try {
        const response = await axios.get(
          `https://api.vimeo.com/videos/${url}`,
          {
            headers: {
              Authorization: "Bearer 0cba90df683053fde3386d325db1f115",
            },
          }
        );

        const videoData = response.data;
        const thumbnailUrl = videoData.pictures.base_link;

        setThumbnailUrl(thumbnailUrl);
        setLoading(false);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de la vidéo Vimeo :",
          error
        );
        setThumbnailUrl(
          "https://www.pole-optitec.com/img/entreprises/default.jpg"
        );
        setLoading(false);
      }
    };

    useEffect(() => {
      getImage();
    }, [url]);

    return (
      <div className={`main-img-thumbnail ${loading ? "skeleton" : ""}`}>
        {loading ? (
          <div className="skeleton-placeholder"></div>
        ) : (
          <>
            <img
              src={thumbnailUrl}
              alt="Vimeo Thumbnail"
              className="main-img"
            />
            <div
              className="lecteur"
              onClick={() => {
                setVideoSelect(url);
                setShowTrailer(true);
              }}
            >
              <div className="triangle"></div>
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <section
      className={`pageSection sliderVideos ${template} ${
        bgColor === "white"
          ? "bg-white"
          : bgColor === "blue"
          ? "bg-blue"
          : bgColor === "rose"
          ? "bg-pink"
          : ""
      }`}
      ref={ref}
    >
      {trad[lang].sliderVideo_title && (
        <h2>
          <strong>{trad[lang].sliderVideo_title}</strong>
        </h2>
      )}

      <div className="gallery" ref={containerRef}>
        {section.listVideo.data.length > 0 ? (
          section.listVideo.data.map((el, index) => (
            <Thumbnail key={index} url={el[lang].video.data} />
          ))
        ) : (
          <p>{trad[lang].no_result}</p>
        )}
      </div>

      {showTrailer && (
        <PopupVimeo
          url={videoSelect}
          handleClose={() => setShowTrailer(false)}
        />
      )}
    </section>
  );
});
