import "./textImgBandeauContainer.scss";
import { motion } from "framer-motion";
import { Button } from "../UI/Button/Button";
import { html, FADE_DURATION, FADE_OFFSET } from "../../helpers";


export const TextImgBandeauContainer = (props) => {

    const { item = {}, reverse } = props;
    const {
        image = {},
        title = {},
        content = {},
        button = {},
    } = item;
    const { title: btnTitle = {}, status: btnStatus = {}, url: btnUrl = {} } = button;

    const viewportOptions = {
        margin: `0px 0px -${FADE_OFFSET}px 0px`,
        once: true
    }

    return (
        <motion.div
            className={`textImgBandeauContainer ${reverse ? "reverse" : ""}`}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: FADE_DURATION }}
            viewport={viewportOptions}
        >

            {image.data &&
                <img src={image.data.path ?? ""} alt={image.data.alt ?? "not found"} className="bandeau" />
            }

            <div className="text">
                
                {title.data &&
                    <p className="title">
                        {title.data}
                    </p>
                }

                {content.data &&
                    <div className="desc">
                        {html(content.data)}
                    </div>
                }

                {btnStatus.data === true &&
                    <Button to={`/${btnUrl.data}`}>
                        {btnTitle.data}
                    </Button>
                }

            </div>

        </motion.div>
    )
}