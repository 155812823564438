import { forwardRef, useContext } from "react";
import "./chiffres.scss";
import { motion } from "framer-motion";
import { SplitFlap } from "../../components/SplitFlap/SplitFlap";
import { FADE_DURATION, FADE_OFFSET } from "../../helpers";
import { StoreContext } from "../../store/store";

export const Chiffres = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        subTitle = {},
        chiffres = {}
    } = section ?? {};
    const { data: items = [] } = chiffres ?? {};
    
    const { lang } = useContext(StoreContext);

    return(
        <motion.section
            className={`pageSection chiffres ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: FADE_DURATION }}
            viewport={{ margin: `0px 0px -${FADE_OFFSET}px 0px`, once: true }}
            ref={ref}
        >

            <h2>
                <strong>{title.data}</strong>
                {subTitle.data && <>
                    <br/>
                    {subTitle.data}
                </>}
            </h2>

            <div className="chiffres-values">

                {Array.from(items).map(el => {
                    const {
                        value = {},
                        title = {}
                    } = el[lang] ?? {};

                    return value.data && (
                        <SplitFlap value={parseInt(value.data)} text={title.data} />
                    )
                })}
                
            </div>

        </motion.section>
    )
})