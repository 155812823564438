import './Loader.scss';

export const Loader = (props) => {
  const {
    color = '#E5007E',
    size,
  } = props;

  return (
    <div
      className="loader"
      style={{
        '--borderColor': color,
        width: size,
        height: size,
      }}
    ></div>
  )
}