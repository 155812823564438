import { forwardRef, useState, useRef, useContext, useEffect } from "react";
import "./contactForm.scss";
import { motion } from "framer-motion";
import {
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import { verifyCAPTCHA, sendContact } from "../../store/index.service";
import { SelectCustom } from "../../components/UI/SelectCustom/SelectCustom";
import { StoreContext } from "../../store/store";
import { trad } from "../../utils/trad";
import { getCookieAuth, FADE_DURATION, FADE_OFFSET } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";
import { useLocation } from "react-router-dom";

export const ContactForm = forwardRef((props, ref) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Vérifiez si les paramètres de la requête sont présents
  const objetParam = searchParams.get("objet");
  const messageParam = searchParams.get("message");

  useEffect(() => {
    if (objetParam && messageParam) {
      setFormData({
        ...formData,
        subject: objetParam,
        message: messageParam,
      });
    }
  }, [objetParam, messageParam]);

  const { section, template, bgColor } = props;
  const { title = {}, subTitle = {}, btnTitle = {}, typeEmail = {} } = section;
  const { data: categoryOptions = [] } = typeEmail;

  const { lang } = useContext(StoreContext);

  const initFormData = {
    name: "",
    email: "",
    company: "",
    country: "",
    subject: "",
    category: "",
    message: "",
  };

  const [formData, setFormData] = useState(initFormData);
  const {
    name,
    email,
    company,
    country,
    subject,
    category,
    message,
  } = formData;

  const [submittedOnce, setSubmittedOnce] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const captchaRef = useRef();

  const handleChange = (key, value) => {
    setFormData({
      ...formData,
      [key]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmittedOnce(true);

    if (name && email && company && country && subject && message) {
      setLoading(true);

      if (getCookieAuth("google_recaptcha")) {
        const CAPTCHA = captchaRef.current;

        if (!CAPTCHA) {
          setLoading(false);
          return false;
        }

        const token = CAPTCHA.getValue();
        CAPTCHA.reset();

        if (!token) {
          setLoading(false);
          return false;
        }

        verifyCAPTCHA(token)
          .then((res) => {
            if (res.data.reponse && res.data.reponse.success === true)
              sendForm();
            else {
              setLoading(false);
              setSuccess(false);
              setError(true);

              setTimeout(() => {
                setError(false);
              }, 5000);
            }
          })
          .catch((err) => {
            console.error(err);
            setLoading(false);
            setSuccess(false);
            setError(true);

            setTimeout(() => {
              setError(false);
            }, 5000);
          });
      } else sendForm();
    }
  };

  const sendForm = () => {
    sendContact(formData)
      .then(() => {
        setSuccess(true);
        setError(false);

        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      })
      .catch((err) => {
        console.error(err);

        setSuccess(false);
        setError(true);

        setTimeout(() => {
          setError(false);
        }, 5000);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <section
      className={`pageSection contactForm ${template} ${
        bgColor === "white" ? "bg-white" : bgColor === "blue" ? "bg-blue"  : bgColor === "rose" ? "bg-pink" :  ""
      }`}
      ref={ref}
    >
      <FadeSection>
        <h2>
          <strong>{title.data}</strong>
          {subTitle.data && (
            <>
              <br />
              {subTitle.data}
            </>
          )}
        </h2>

        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <TextField
              label={`${trad[lang].contact_form_name}*`}
              variant="standard"
              fullWidth
              size="small"
              value={name}
              onChange={(e) => handleChange("name", e.target.value)}
              error={submittedOnce && !name}
            />

            <TextField
              label={`${trad[lang].contact_form_email}*`}
              variant="standard"
              fullWidth
              type="email"
              size="small"
              value={email}
              onChange={(e) => handleChange("email", e.target.value)}
              error={submittedOnce && !email}
            />
          </div>

          <div className="form-row">
            <TextField
              label={`${trad[lang].contact_form_company}*`}
              variant="standard"
              fullWidth
              size="small"
              value={company}
              onChange={(e) => handleChange("company", e.target.value)}
              error={submittedOnce && !company}
            />

            <TextField
              label={`${trad[lang].contact_form_country}*`}
              variant="standard"
              fullWidth
              size="small"
              value={country}
              onChange={(e) => handleChange("country", e.target.value)}
              error={submittedOnce && !country}
            />
          </div>

          <TextField
            label={`${trad[lang].contact_form_subject}*`}
            variant="standard"
            fullWidth
            size="small"
            value={subject}
            onChange={(e) => handleChange("subject", e.target.value)}
            error={submittedOnce && !subject}
          />

          <SelectCustom
            label={`${trad[lang].contact_form_category}`}
            value={category}
            dataKey="category"
            handleChange={handleChange}
            options={
              Array.isArray(categoryOptions)
                ? categoryOptions.map((el) => ({
                    value: el.id,
                    label:
                      el[lang] && el[lang].type
                        ? el[lang].type.data
                        : "undefined",
                  }))
                : []
            }
            error={submittedOnce && !category}
          />

          <TextField
            label={`${trad[lang].contact_form_message}*`}
            variant="standard"
            fullWidth
            size="small"
            value={message}
            onChange={(e) => handleChange("message", e.target.value)}
            multiline
            error={submittedOnce && !message}
          />

          {/* <FormGroup>
            <FormControlLabel
              checked={acceptNewsletter}
              onChange={(e) =>
                handleChange("acceptNewsletter", e.target.checked)
              }
              control={
                <Checkbox
                  sx={{
                    color: "#fff",
                    "&.Mui-checked": {
                      color: "#fff",
                    },
                  }}
                />
              }
              label={`${trad[lang].contact_form_newsletter}`}
            />
          </FormGroup> */}

          {getCookieAuth("google_recaptcha") && (
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_reCAPTCHA_SITE_KEY}
              theme="dark"
              ref={captchaRef}
            />
          )}

          <div className="submit-container">
            <button className="btn" type="submit">
              {btnTitle.data}
              {loading && <CircularProgress size={16} className="loader" />}
            </button>

            {success ? (
              <p>{trad[lang].contact_form_success}</p>
            ) : error ? (
              <p className="error">{trad[lang].error}</p>
            ) : null}
          </div>
        </form>
      </FadeSection>
    </section>
  );
});
