import { forwardRef } from "react";
import "./imgSection.scss";
import { motion } from "framer-motion";
import { FADE_DURATION, FADE_OFFSET } from "../../helpers";

import placeholder from "../../utils/img/hero_placeholder_1.jpg";

export const ImgSection = forwardRef((props, ref) => {

    const { section } = props;
    const { template } = section;

    return(
        <motion.section
            className={`pageSection imgSection ${template}`}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: FADE_DURATION }}
            viewport={{ margin: `0px 0px -${FADE_OFFSET}px 0px`, once: true }}
            ref={ref}
        >

            <img src={placeholder} alt="alt" />

        </motion.section>
    )
})