import { forwardRef, useContext } from "react";
import "./textImgBandeau.scss";
import { TextImgBandeauContainer } from "../../components/TextImgBandeauContainer/TextImgBandeauContainer";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";


export const TextImgBandeau = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        subTitle = {},
        textImgBandeau = {}
    } = section;
    const { data: items = [] } = textImgBandeau;

    const { lang } = useContext(StoreContext);

    return(
        <section
            className={`pageSection textImgBandeau ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <h2>
                    <strong>{title.data}</strong>
                    {subTitle.data && <>
                        <br/>
                        {subTitle.data}
                    </>}
                </h2>

                {items.map((el, i) =>
                    <TextImgBandeauContainer
                        item={el[lang]}
                        reverse={i%2 !== 0}
                    />
                )}

            </FadeSection>


        </section>
    )
})