import { forwardRef, useContext } from "react";
import "./autresEntites.scss";
import { motion } from "framer-motion";
import { Button } from "../../components/UI/Button/Button";
import { StoreContext } from "../../store/store";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";


export const AutresEntites = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        listEntity = {},
        button = {}
    } = section;
    const { data: items = [] } = listEntity;
    const { title: btnTitle = {}, status: btnStatus = {}, url: btnUrl = {} } = button;

    const { lang } = useContext(StoreContext);

    return(
        <motion.section
            className={`pageSection autresEntites ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <h2>
                    <strong>{title.data}</strong>
                </h2>

                <div className="list">

                    {items.map(el => {

                        const {
                            title = {},
                            content = {},
                            logo = {},
                            image = {}
                        } = el[lang] ?? {};

                        return (
                            <div className="list-item">

                                <img src={image.data ? image.data.path : ""} alt={image.data ? image.data.alt : "not found"} className="main-logo" />

                                <div className="text">

                                    <div className="desc">
                                        <p><strong>{title.data}</strong></p>
                                        {html(content.data)}
                                    </div>

                                    <div className="item-footer">

                                        {btnStatus.data === true &&
                                            <Button to={`/${btnUrl.data}`}>
                                                {btnTitle.data}
                                            </Button>
                                        }

                                        <img src={logo.data ? logo.data.path : ""} alt={logo.data ? logo.data.alt : "not found"} className="full-logo" />

                                    </div>


                                </div>

                            </div>
                        )
                    })}

                </div>
                
            </FadeSection>


        </motion.section>
    )
})