import { forwardRef, useContext, useRef, useState } from "react";
import "./articleList.scss";
import { useScroll, useMotionValueEvent } from "framer-motion";
import { StoreContext } from "../../store/store";
import { ArticleItem } from "./ArticleItem";
import { trad } from "../../utils/trad";

export const ArticleList = forwardRef((props, ref) => {

    const { section, template, isSection, bgColor } = props;
    const {
        articles = {}
    } = section;
    const { data: items = [] } = articles;

    const { filtersData, lang } = useContext(StoreContext);

    const containerRef = useRef();
    const { scrollYProgress } = useScroll({
        target: containerRef,
        offset: ["start end", "end 85vh"]
    });

    const count = 6;

    const [limit, setLimit] = useState(count);

    const filteredItems = () => {
        let data = items;

        for( const [key, value] of Object.entries(filtersData) ) {

            if(key === "search")
                data = data.filter(el => el[lang].title.data.toLowerCase().includes(value.toLowerCase()));
            else if(value && value.length > 0)
                data = data.filter(el => {
                    const theme = el[lang] && el[lang].thematique ? el[lang].thematique.data : "";
                    return value.map(f => f.label).includes(theme);
                });
        }

        return data;
    }

    const filteredData = filteredItems();

    useMotionValueEvent(scrollYProgress, "change", (latest) => {

        if(latest >= 1 && limit < filteredData.length)
            setLimit(limit + count);

    });

    return(
        <section
            className={`pageSection articleList ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            {isSection &&
                <h2><strong>{trad[lang].might_interest}</strong></h2>
            }

            <div
                className="gallery"
                ref={containerRef}
            >

                {filteredData.length > 0 ? 
                    filteredData
                        .slice(0, limit)
                        .map(el =>
                            <ArticleItem
                                title={el[lang].title}
                                thematique={el[lang].thematique}
                                key={el.id}
                            />
                        )
                :
                    <p>{trad[lang].no_result}</p>
                }

            </div>

        </section>
    )
})