import { useState, useContext } from "react";
import "./metiersDrawers.scss";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { StoreContext } from "../../../store/store";

const Drawer = (props) => {

    const { drawerOpen, title, i, setOpen, entities = [], lang } = props;

    const isOpen = drawerOpen === i;
    
    return (
        <div
            className="sous-metier-drawer"
            onMouseEnter={() => setOpen(i)}
            onMouseLeave={() => setOpen(false)}
        >

            <h4>
                {title}
            </h4>

            <motion.div
                className="entites"
                initial="closed"
                animate={isOpen ? "open" : "closed"}
                variants={{
                    open: { opacity: 1, height: "auto" },
                    closed: { opacity: 0, height: 0 }
                }}
                transition={{
                    duration: 0.2,
                    ease: "easeOut"
                }}
            >

                {entities.map(el =>
                    <Link
                        className="entite-img-container"
                        to={`/${lang}/${el.slug}`}
                        key={el.id}
                    >
                        <img src={el.logoWhite ? el.logoWhite.path : ""} alt={el.logoWhite ? el.logoWhite.alt : "not found"} />
                    </Link>
                )}

            </motion.div>

        </div>
    )
}

export const MetiersDrawers = ({ title, sousMetiers = [] }) => {

    const [open, setOpen] = useState(false);

    const { lang } = useContext(StoreContext);

    return(
        <div className="metiers-drawers">

            <h3>{title}</h3>

            <div className="sous-metiers">

                {sousMetiers.map((el, i) =>    
                    <Drawer
                        title={lang === "fr" ? el.title : el[`title_${lang}`]}
                        drawerOpen={open}
                        i={i+1}
                        setOpen={setOpen}
                        key={el.id}
                        entities={el.entity}
                        lang={lang}
                    />
                )}

            </div>

        </div>
    )
}