import { forwardRef, useRef, useContext } from "react";
import "./sliderMarche.scss";
import { Link } from "react-router-dom";
import { Button } from "../../components/UI/Button/Button";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

import arrowRight from "../../utils/img/arrow-right-black.svg";

export const SliderMarche = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { title = {}, subTitle = {}, button = {} } = section;
  const {
    title: btnTitle = {},
    status: btnStatus = {},
    url: btnUrl = {},
  } = button;

  const prevRef = useRef();
  const nextRef = useRef();

  const { lang } = useContext(StoreContext);

  const WW = window.innerWidth;

  return (
    section.listMarche.data.length > 0 && (
      <section
        className={`pageSection sliderMarche ${template} ${
          bgColor === "black"
            ? ""
            : bgColor === "blue"
            ? "bg-blue"
            : bgColor === "rose"
            ? "bg-pink"
            : "bg-white"
        }`}
        ref={ref}
      >
        <FadeSection>
          <h2>
            <strong>{title.data}</strong>
            {subTitle.data && (
              <>
                <br />
                {subTitle.data}
              </>
            )}
          </h2>

          <div className="slider-container">
            <div className="arrows-container">
              <img
                ref={prevRef}
                src={arrowRight}
                alt="<"
                className="arrow prev"
              />
              <img
                ref={nextRef}
                src={arrowRight}
                alt=">"
                className="arrow next"
              />
            </div>

            <Swiper
              modules={[Navigation]}
              onInit={(swiper) => {
                swiper.params.navigation.prevEl = prevRef.current;
                swiper.params.navigation.nextEl = nextRef.current;
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              className="news-slider"
              allowTouchMove={WW <= 1024 ? true : false}
              slidesPerView={1}
              slidesPerGroup={1}
              spaceBetween={25}
              breakpoints={{
                600: {
                  slidesPerView: 2,
                  slidesPerGroup: 2,
                },
                1024: {
                  slidesPerView: 3,
                  slidesPerGroup: 3,
                },
                1300: {
                  slidesPerView: 4,
                  slidesPerGroup: 4,
                },
              }}
            >
              {section.listMarche.data.map((el) => {
                const {
                  externalUrl,
                  image = {},
                  title = {},
                  content = {},
                } = el[lang] ?? {};
                const { infoPage = {} } = el;
                const { slug = {} } = infoPage[lang] ?? {};
                return (
                  <SwiperSlide key={el.id}>
                    {externalUrl && externalUrl.data != "" ? (
                      <Link
                        to={externalUrl && externalUrl.data}
                        target={externalUrl ? "_blank" : null}
                      >
                        <img
                          src={image.data ? image.data.path : ""}
                          alt={image.data ? image.data.alt : "not found"}
                        />
                        <p>{title.data}</p>
                        <span className="city">
                          <b>{content.data}</b>
                        </span>
                      </Link>
                    ) : (
                      <Link to={null}>
                        <img
                          src={image.data ? image.data.path : ""}
                          alt={image.data ? image.data.alt : "not found"}
                        />
                        <p>{title.data}</p>
                        <span className="city">
                          <b>{content.data}</b>
                        </span>
                      </Link>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>

          {btnStatus.data === true && (
            <Button to={`/${btnUrl.data}`}>{btnTitle.data}</Button>
          )}
        </FadeSection>
      </section>
    )
  );
});
