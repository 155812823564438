import { forwardRef, useRef, useContext } from "react";
import "./sliderEquipe.scss";
import { Teammate } from "../../components/Teammate/Teammate";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';

import arrowRight from "../../utils/img/arrow-right.svg";

export const SliderEquipe = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        teammateItem = {}
    } = section;

    const { data: teammates = [] } = teammateItem;

    const { lang } = useContext(StoreContext);

    const prevRef = useRef();
    const nextRef = useRef();

    const WW = window.innerWidth;

    return(
        <section
            className={`pageSection sliderEquipe ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >
            <FadeSection>

                <h2>
                    <strong>{title.data}</strong>
                </h2>

                <div className="slider-container">

                    <div className="arrows-container">

                        <img ref={prevRef} src={arrowRight} alt="<" className="arrow prev" />
                        <img ref={nextRef} src={arrowRight} alt=">" className="arrow next" />

                    </div>

                    <Swiper
                        modules={[Navigation]}
                        onInit={(swiper) => {
                            swiper.params.navigation.prevEl = prevRef.current;
                            swiper.params.navigation.nextEl = nextRef.current;
                            swiper.navigation.init();
                            swiper.navigation.update();
                        }}
                        className="projets-slider"
                        allowTouchMove={WW <= 1024 ? true : false}
                        slidesPerView={1}
                        slidesPerGroup={1}
                        spaceBetween={5}
                        centeredSlides
                        slideToClickedSlide
                        breakpoints={{
                            600: {
                                slidesPerView: 2
                            },
                            1024: {
                                slidesPerView: 3
                            }
                        }}
                    >

                        {teammates.map(el => {

                            const {
                                firstName = {},
                                lastName = {},
                                post = {},
                                linkedin = {},
                                image = {},
                                introduction = {}
                            } = el[lang] ?? {};
                            const { data: entities = [] } = el[lang] && el[lang]["spe-entity"] ? el[lang]["spe-entity"] : {};
                            const entity = Array.isArray(entities) ? entities[0] : null;

                            return (
                                <SwiperSlide key={el.id}>
                                    <Teammate
                                        img={image.data ? image.data.path : ""}
                                        alt={image.data ? image.data.alt : "not found"}
                                        title={`${firstName.data} ${lastName.data}`}
                                        job={post.data}
                                        linkedinUrl={linkedin.data}
                                        text={introduction.data}
                                        entity={entity}
                                    />
                                </SwiperSlide>
                            )

                        })}
                        
                    </Swiper>

                </div>

            </FadeSection>

        </section>
    )
})