import { forwardRef } from "react";
import "./presentation.scss";
import { Button } from "../../components/UI/Button/Button";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const Presentation = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        subTitle = {},
        text = {},
        button = {},
        image = {},
    } = section;
    const { title: btnTitle = {}, status: btnStatus = {}, url: btnUrl = {} } = button;

    const { data: imgData = {} } = image;
    const { path, alt } = imgData ?? {};

    return(
        <section
            className={`pageSection presentation ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <img src={path ?? ""} alt={alt ?? "not found"} />

                <div className="text">

                    <h2>
                        <strong>{title.data}</strong>
                        {subTitle.data && <>
                            <br/>
                            {subTitle.data}
                        </>}
                    </h2>

                    {text.data &&
                        <div>
                            {html(text.data)}
                        </div>
                    }

                    {btnStatus.data === true &&
                        <Button to={`/${btnUrl.data}`}>
                            {btnTitle.data}
                        </Button>
                    }

                </div>

            </FadeSection>

        </section>
    )
})