import { forwardRef, useRef, useContext } from "react";
import "./sliderCritiques.scss";
import { Rating, styled } from "@mui/material";
import { trad } from "../../utils/trad";
import { StoreContext } from "../../store/store";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import arrowRight from "../../utils/img/arrow-right.svg";


export const SliderCritiques = forwardRef((props, ref) => {

    const { section, template } = props;
    const {
        critique = {}
    } = section;
    const { data: items = [] } = critique;

    const { lang } = useContext(StoreContext);

    const prevRef = useRef();
    const nextRef = useRef();

    const WW = window.innerWidth;

    const StyledRating = styled(Rating)({
        '& .MuiRating-iconFilled': {
            color: '#000',
        },
        '& .MuiRating-iconHover': {
            color: '#000',
        },
    });

    return items.length > 0 && (
        <section
            className={`pageSection sliderCritiques ${template} bg-white`}
            ref={ref}
        >
            <FadeSection>

                <div className="projets-header">

                    <h2>
                        <strong>{trad[lang].critiques}</strong>
                    </h2>

                    <div className="arrows-container">

                        <img ref={prevRef} src={arrowRight} alt="<" className="arrow prev" />
                        <img ref={nextRef} src={arrowRight} alt=">" className="arrow next" />

                    </div>

                </div>

                <Swiper
                    modules={[Navigation]}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    className="critiques-slider"
                    allowTouchMove={WW <= 1024 ? true : false}
                    slidesPerView={1}
                    slidesPerGroup={1}
                    spaceBetween={30}
                    breakpoints={{
                        600: {
                            slidesPerView: 2,
                            slidesPerGroup: 2
                        },
                        1024: {
                            slidesPerView: 3,
                            slidesPerGroup: 3
                        }
                    }}
                >

                    {items.map(el => {

                        const {
                            title = {},
                            content = {},
                            notes = {}
                        } = el[lang] ?? {};

                        return (
                            <SwiperSlide key={el.id}>

                                <p className="title">{title.data}</p>

                                <StyledRating
                                    value={notes.data ? parseInt(notes.data) : 0}
                                    readOnly
                                    className="rating"
                                />

                                {html(content.data)}

                            </SwiperSlide>
                        )

                    })}
                    
                </Swiper>

            </FadeSection>

        </section>
    )
})