import { forwardRef, useContext } from "react";
import "./metiers.scss";
import { MetiersDrawers } from "./metiersDrawers/MetiersDrawers";
import { ContactUs } from "../contactUs/ContactUs";
import { html } from "../../helpers";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const Metiers = forwardRef((props, ref) => {

    const { section, template, metiers = [], bgColor } = props;
    const {
        title = {},
        subTitle = {},
        content = {},
        contactText = {},
        button = {}
    } = section;

    const { lang } = useContext(StoreContext);

    return(
        <section
            className={`pageSection metiers ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <h2>
                    <strong>{title.data}</strong>
                    {subTitle.data && <>
                        <br/>
                        {subTitle.data}
                    </>}
                </h2>

                {content.data &&
                    <div className="desc">
                        {html(content.data)}
                    </div>
                }

                <div className="metiers-container">

                    {metiers.map(el =>
                        <MetiersDrawers
                            key={el.id}
                            title={lang === "fr" ? el.title : el[`title_${lang}`]}
                            sousMetiers={el.underProfession}
                        />
                    )}

                </div>

                <ContactUs
                    section={{
                        title: contactText,
                        button
                    }}
                />

            </FadeSection>

        </section>
    )
})