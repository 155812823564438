import React, { useEffect, useContext, useCallback } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './app.scss';
import { StoreContext } from "./store/store";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { getMenusData } from './store/index.service';

// COMPONENTS
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { SectionManager } from './components/SectionManager/SectionManager';
import { VideoIntro } from './components/VideoIntro/VideoIntro';

const theme = createTheme(
    {
        palette: {
            grey: {
                main: "#999"
            },
            white: {
                main: "#fff"
            },
            black: {
                main: "#000"
            }
        },
        typography: {
            fontFamily: `"Urbanist"`,
            fontSize: window.innerWidth >= 1024 ? 19.5 : 18
        },
        components: {
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        fontWeight: "bold",
                        letterSpacing: .39
                    }
                }
            }
        }
    },
);


export const App = () => {
    const { pageData, lang, sectionsDOM, setSectionsDOM, headerDOM, setHeaderDOM, setFiltersData, setMenusData } = useContext(StoreContext);

    const navigate = useNavigate();

    const decideHeaderColor = useCallback(() => {

        if(headerDOM)
            if(window.document.documentElement.scrollTop <= 0)
                if(sectionsDOM[0] && sectionsDOM[0].domEl.classList.contains("with-img-banner"))
                    headerDOM.classList.add('transparent-white');
                else
                    headerDOM.classList.add('transparent');
            else {
                headerDOM.classList.remove('transparent-white');
                headerDOM.classList.remove('transparent');
            }
            
        if(sectionsDOM)
            sectionsDOM.forEach((sect, i) => {

                if( (window.scrollY + 58) >= (i === 0 ? 0 : sect.rect.top) && window.scrollY <= sect.rect.bottom ) {

                    sectionsDOM[i].domEl.classList.contains('bg-white') ?
                        headerDOM.classList.add('bg-white')
                    :
                        headerDOM.classList.remove('bg-white');
                    return;
                }
            });

    }, [sectionsDOM, headerDOM]);

    useEffect(()=>  {

        if("scrollRestoration" in window.history)
            window.history.scrollRestoration = "manual";

        //locale
        if(!localStorage.getItem("currentLanguage")){
            if(navigator.language.toLocaleLowerCase().slice(0, 2) === "fr")
                localStorage.setItem("currentLanguage", "fr");
            else
                localStorage.setItem("currentLanguage", "en");}

        getMenusData()
            .then(res => {
                setMenusData(res.data);
            })
            .catch(err => console.error(err))

        if(window.location.pathname === "/")
            navigate(`/${lang}`)

    }, []);

    useEffect(()=>  {

        localStorage.setItem('currentLanguage', lang);

    }, [lang]);

    useEffect(() => {
        const sections = document.querySelectorAll('.pageSection');
        const header = document.querySelector('#nav-header');

        window.document.body.scrollTop = 0;
        window.document.documentElement.scrollTop = 0;

        if(window.document.documentElement.scrollTop <= 0 && header)
            if(sections[0] && sections[0].classList.contains("with-img-banner"))
                header.classList.add('transparent-white');
            else {
                header.classList.remove('transparent-white');
                header.classList.add('transparent');
            }

        if(header) {

            setHeaderDOM(header);

            if(sections.length > 0 && sections[0].classList.contains('bg-white'))
                header.classList.add('bg-white');
            else
                header.classList.remove('bg-white');
        }

        const sectionsInfo = [];

        sections.forEach((s) => {
            sectionsInfo.push({
                domEl: s,
                rect: s.getBoundingClientRect()
            });
        });

        setSectionsDOM(sectionsInfo);
        // setFiltersData({});

        window.scrollTo(0, 0);

    }, [pageData]);

    useEffect(() => {

        decideHeaderColor();

        window.addEventListener('scroll', decideHeaderColor);

        return () => window.removeEventListener("scroll", decideHeaderColor);

    }, [decideHeaderColor]);

    return (
        <ThemeProvider theme={theme}>
            <div id="App">

                <Header />

                <Routes>

                    <Route path="/:locale/*" element={<SectionManager />} />

                </Routes>

                <Footer />

                {window.innerWidth > 1024 &&
                    <VideoIntro />
                }

            </div>
        </ThemeProvider>
    );
}