import { forwardRef, useState, useContext, useRef } from "react";
import "./newsGallery.scss";
import { useScroll, useMotionValueEvent } from "framer-motion";
import { StoreContext } from "../../store/store";
import { NewsItem } from "./NewsItem";
import { trad } from "../../utils/trad";
import { Button } from "@mui/material";

export const NewsGallery = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { actu = {} } = section;
  const { data: items = [] } = actu;

  const { filtersData, lang } = useContext(StoreContext);

  const containerRef = useRef();
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ["start end", "end 75vh"],
  });

  const count = 12;

  const [limit, setLimit] = useState(count);

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    if (latest >= 1 && limit < filteredData.length) setLimit(limit + count);
  });

  const filteredItems = () => {
    let data = items;

    for (const [key, value] of Object.entries(filtersData)) {
      if (key === "search")
        data = data.filter((el) =>
          el[lang].title.data.toLowerCase().includes(value.toLowerCase())
        );
      else if (value && value.length > 0)
        data = data.filter((el) => {
          const theme =
            el[lang] && el[lang].thematique ? el[lang].thematique.data : "";
          return value.map((f) => f.label).includes(theme);
        });
    }

    return data;
  };

  const filteredData = filteredItems();

  return (
    <section
      className={`pageSection newsGallery ${template} ${
        bgColor === "white"
          ? "bg-white"
          : bgColor === "blue"
          ? "bg-blue"
          : bgColor === "rose"
          ? "bg-pink"
          : ""
      }`}
      ref={ref}
    >
      {section.title && (
        <h2>
          <strong>{section.title.data}</strong>
        </h2>
      )}

      <div className="gallery" ref={containerRef}>
        {filteredData.length > 0 ? (
          filteredData
            .slice(0, limit)
            .map((el) => (
              <NewsItem
              externalUrl={el[lang].externalUrl}
                image={el[lang].image}
                title={el[lang].title}
                thematique={el[lang].thematique}
                date={el[lang].date}
                key={el.id}
              />
            ))
        ) : (
          <p>{trad[lang].no_result}</p>
        )}
      </div>

      {section.btn && (
        <div className="gallery-btn">
          <Button className="btn" to={`/`}>
            Contactez-nous
          </Button>
        </div>
      )}
    </section>
  );
});
