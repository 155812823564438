import { forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { FADE_DURATION } from "../../helpers";

const LinkRef = forwardRef((props, ref) => {

return(
    <Link {...props} ref={ref}>
        {props.children}
    </Link>
)
}
    
)

const MotionLink = motion(LinkRef);

export const NewsItem = (props) => {

    const { externalUrl, image = {}, title = {}, thematique = {}, date = {} } = props;

    const { pathname } = useLocation();

    const windowHeight = window.innerHeight;

    const viewportOptions = {
        margin: `0px 0px -${(windowHeight/4)}px 0px`,
        once: true
    };

    const handleClick = () => {
        if (externalUrl && externalUrl.data) {
          window.open(externalUrl.data, "_blank");
        } else {
          window.location.href = `${pathname}/${title.slug}`;
        }
      };

    return(
        <MotionLink
            className="news-item"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: FADE_DURATION }}
            viewport={viewportOptions}
            onClick={handleClick}

        >

            <div className="img-container">

                <img
                    src={image.data ? image.data.path : ""}
                    alt={image.data ? image.data.alt : "not found"}
                    className="main-img"
                />
                
            </div>

            <em className="title">{title.data}</em>

            <p className="desc">
                {/* {thematique.data} */}
                {date.data}
            </p>

        </MotionLink>
    )
}