import { useContext } from "react";
import "./Footer.scss";
import { StoreContext } from "../../store/store";
import { trad } from "../../utils/trad";
import { html } from "../../helpers";

import { ListLink } from "../Header/ListLink";

import iconFB from "../../utils/img/iconFacebook.svg";
import iconIG from "../../utils/img/iconInstagram.svg";
import iconLIN from "../../utils/img/iconLinkedin.svg";
import iconTW from "../../utils/img/iconTwitter.svg";
import iconYT from "../../utils/img/iconYoutube.svg";
import iconVM from "../../utils/img/iconVimeo.svg";


const SOCIALS = {
  iconFacebook: iconFB,
  iconTwitter: iconTW,
  iconInstagram: iconIG,
  iconYoutube: iconYT,
  iconLinkedin: iconLIN,
  iconVimeo: iconVM
};

export const Footer = () => {
  const { menusData, lang, pageData } = useContext(StoreContext);
  const { sectionPage } = pageData ?? {};
  const { footer: footerData = {} } = menusData ?? {};
  const { info = {}, menuFooterFirst: menu } = footerData;
  const {
    address = {},
    content = {},
    linkedin = {},
    facebook = {},
    insta= {},
    vimeo= {},

    logo_lfa = {},
    logo_superprod = {},
    logo_trusted = {},
    phone = {},
    twitter = {},
    zip = {},
  } = info;
  const { content: footerDesc = {} } = content[lang] ?? {};

  const socialNetworks = [
    { ...linkedin, icon: "iconLinkedin", altMedia: "LinkedIn" },
    { ...facebook, icon: "iconFacebook", altMedia: "Facebook" },
    { ...insta, icon: "iconInstagram", altMedia: "Instagram" },
    { ...twitter, icon: "iconTwitter", altMedia: "Twitter" },
    { ...vimeo, icon: "iconVimeo", altMedia: "Vimeo" },
  ];

  const renderNav = () => {
    const height =
      WW < 1024 || WW >= 1400 ? Math.ceil(menu.length / 2) * 40 : 239;

    return (
      <nav style={{ height }}>
        {menu.map((lk) => (
          <ListLink lk={lk} key={lk.id} />
        ))}
      </nav>
    );
  };

  const WW = window.innerWidth;

  return (
    footerData && (
      <footer style={{ display: sectionPage ? undefined : "none" }}>
        <div id="main-footer">
          <img
            src={logo_superprod.data ? logo_superprod.data.path : ""}
            alt="Superights"
            className="logo-footer"
          />

          <div className="footer-container">
           

            <div className="footer-content">
              {menu && renderNav()}

              <div className="infos">
                <div className="footer-contact-infos">
                  <div className="listSocials">
                    {socialNetworks
                      .filter((el) => el.data)
                      .map((social, i) => {
                        const contenuBaliseP = social.data;
                        const url = contenuBaliseP.replace(/<\/?p>/g, "");
                        return (
                          <a
                            key={i}
                            className="socialNetwork"
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={SOCIALS[social.icon]}
                              alt={social.altMedia}
                            />
                          </a>
                        );
                      })}
                  </div>

                  <p className="address">
                    {html(address.data)}
                    {html(zip.data)}
                    {html(phone.data)}
                  </p>
                </div>

                {/* {WW < 1024 &&
                                <div className="fimg">
                                    {logo_lfa.data &&
                                        <img src={logo_lfa.data.path} alt={logo_lfa.data.path} />
                                    }
                                    {logo_trusted.data &&
                                        <img src={logo_trusted.data.path} alt={logo_trusted.data.path} />
                                    }
                                </div>
                            } */}
              </div>
            </div>
          </div>
        </div>

        <div id="copyright">
          <span>
            &copy; Copyright {new Date().getFullYear()} Superights.{" "}
            {trad[lang].copyright}
          </span>
        </div>
      </footer>
    )
  );
};
