import { forwardRef } from "react";
import "./recrut.scss";
import { Button } from "../../components/UI/Button/Button";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";


export const Recrut = forwardRef((props, ref) => {

    const { section = {}, isSection, bgColor } = props;
    const {
        image = {},
        title = {},
        subTitle = {},
        content = {},
        button = {}
    } = section;
    const { title: btnTitle = {}, status: btnStatus = {}, url: btnUrl = {} } = button;

    return(
        <section
            className={`recrut ${isSection ? "pageSection" : ""} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <img src={image.data ? image.data.path : ""} alt={image.data ? image.data.alt : "not found"} className="main-img" />

                <div className="infos">

                    <div className="text">

                        <h2>
                            <strong>{title.data}</strong> {subTitle.data}
                        </h2>

                        <div className="desc">
                            {html(content.data)}
                        </div>

                    </div>

                    {btnStatus.data === true &&
                        <Button to={`/${btnUrl.data}`}>
                            {btnTitle.data}
                        </Button>
                    }
                    
                </div>

            </FadeSection>



        </section>
    )
})