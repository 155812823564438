import "./autocompleteCustom.scss";
import {
  Autocomplete,
  TextField,
  SvgIcon,
  Popper,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

const CustomIcon = (props) => {
  return (
    <SvgIcon fontSize="small">
      <path
        d="M0,74.525a8.845,8.845,0,0,1,7.8,7.8,8.845,8.845,0,0,1,7.8-7.8"
        transform="translate(0.166 -65)"
        fill="none"
        stroke="#fff"
        strokeWidth="3"
      />
    </SvgIcon>
  );
};

export const AutocompleteCustom = (props) => {
  const {
    label,
    value,
    handleChange = () => {},
    options = [],
    dataKey,
    multiple,
    isOptionEqualToValue = (option, newValue) => option.id === newValue.id,
    getOptionLabel = (option) => option.label,
    className,
    staticPopper,
  } = props;

  const WW = window.innerWidth;

  return (
    <div className="autocomplete-custom">
      <Autocomplete
        disablePortal
        className={className ?? ""}
        fullWidth
        size="small"
        options={options}
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={isOptionEqualToValue}
        value={value ?? (multiple ? [] : null)}
        onChange={(event, newValue) => {
          handleChange(dataKey, newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="standard"
            size="small"
          />
        )}
        popupIcon={<CustomIcon />}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        PopperComponent={(props) => (
          <Popper
            {...props}
            keepMounted
            className={staticPopper ? "static-popper" : ""}
            sx={{
              height: `${(options.length ?? 1) * 34 + 8}px`,
            }}
          />
        )}
        PaperComponent={(props) => (
          <Paper
            {...props}
            elevation={0}
            sx={{
              backgroundColor: "transparent",
            }}
          />
        )}
        renderOption={(props, option, { selected }) => {
          return (
            <li
              {...props}
              style={{
                width: "100%",
                padding: "5px 5px 5px 0",
                minHeight: "unset",
              }}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                width={"100%"}
                justifyContent={"space-between"}
              >
                <Typography
                  color={"text.primary"}
                  fontWeight={selected ? "bold" : "normal"}
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {option.label}
                </Typography>

                {selected && <ClearIcon />}
              </Box>
            </li>
          );
        }}
      />
    </div>
  );
};
