import { forwardRef, useContext } from "react";
import "./prixFestiv.scss";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const PrixFestiv = forwardRef((props, ref) => {
  const { isSection, title, items = [], bgColor } = props;

  const { lang } = useContext(StoreContext);

  return (
    items.length > 0 && (
      <section
        className={`prixFestiv ${isSection ? "pageSection" : ""} ${
          bgColor === "white"
            ? "bg-white"
            : bgColor === "blue"
            ? "bg-blue"
            : bgColor === "rose"
            ? "bg-pink"
            : ""
        }`}
        ref={ref}
      >
        <FadeSection>
          <h2>
            <strong>{title}</strong>
          </h2>

          <ul>
            {items.map((el, index) => {
              const { title = {} } = el[lang] ?? {};

              return <li> {title?.data}</li>;
            })}
          </ul>
        </FadeSection>
      </section>
    )
  );
});
