import { forwardRef, useContext, useState, useEffect } from "react";
import "./heading.scss";
import { useLocation, useSearchParams } from "react-router-dom";
import { motion } from "framer-motion";
import { Button } from "../../components/UI/Button/Button";
import { AutocompleteCustom } from "../../components/UI/AutocompleteCustom/AutocompleteCustom";
import { SearchInput } from "../../components/UI/SearchInput/SearchInput";
import { StoreContext } from "../../store/store";
import TuneIcon from "@mui/icons-material/Tune";
import { trad } from "../../utils/trad";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const Heading = forwardRef((props, ref) => {
  const { section, template, bgColor } = props;
  const { title = {}, filters, button = {} } = section;

  const {
    title: btnTitle = {},
    status: btnStatus = {},
    url: btnUrl = {},
  } = button;

  const { filtersData, setFiltersData, pageData, lang } =
    useContext(StoreContext);

  const { filters: storeFilters = {} } = pageData;
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const [showFilters, setShowFilters] = useState(false);
  const [mounted, setMounted] = useState(false);

  const windowHeight = window.innerHeight;
  const WW = window.innerWidth;

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (mounted) {
      localStorage.removeItem("filterEntity");
      setFiltersData({});
    }
  }, [pathname]);

  useEffect(() => {
    const entityQuery = searchParams.get("entity");

    if (entityQuery) {
      const idEntity = parseInt(entityQuery);

      if (idEntity && Array.isArray(storeFilters.filterCible)) {
        const newFilter = storeFilters.filterCible.find(
          (el) => el.id === idEntity
        );

        if (newFilter)
          setFiltersData({
            ...filtersData,
            entity: [{ id: newFilter.id, label: newFilter.title }],
          });
      }
    }
  }, [searchParams]);

  const handleChange = (key, value) => {
    setFiltersData({
      ...filtersData,
      [key]: value,
    });
  };

  const deleteFilters = () => {
    setFiltersData({});
    handleMenu(false);
  };

  const handleMenu = (value) => {
    setShowFilters(value);

    if (value) document.documentElement.classList.add("menu-open");
    else document.documentElement.classList.remove("menu-open");
  };

  const generateFilters = () => {
    let data = [];

    filters.forEach((el, i) => {
      switch (el.type) {
        case "select":
          data.push(
            <AutocompleteCustom
              label={trad[lang][`filter_${el.options}`]}
              value={filtersData[el.options]}
              staticPopper
              dataKey={el.options}
              handleChange={handleChange}
              options={getOptions(el.options) ?? []}
              multiple
              key={i}
            />
          );
          break;
        case "search":
          data.push(
            <SearchInput
              label={trad[lang][`filter_search`]}
              value={filtersData["search"]}
              dataKey={"search"}
              handleChange={handleChange}
              key={i}
            />
          );
          break;
      }
    });

    return data;
  };

  const getOptions = (label) => {
    switch (label) {
      case "metier":
        return storeFilters.filterGenre[lang].map((el, i) => ({
            id: i,
            label: el,
          }));
      case "entity":
        return storeFilters.filterCible[lang].map((el, i) => ({ id: i, label: el }));
      case "thematique":
        return storeFilters.filterNews.map((el, i) => ({ id: i, label: el }));
      default:
        return [];
    }
  };

  return (
    <motion.section
      className={`pageSection heading ${template} ${
        bgColor === "white"
          ? "bg-white"
          : bgColor === "blue"
          ? "bg-blue"
          : bgColor === "rose"
          ? "bg-pink"
          : ""
      }`}
      ref={ref}
      initial="closed"
      animate={showFilters ? "open" : "closed"}
      variants={{
        closed: {
          height: "auto",
          position: "relative",
        },
        open: {
          height: windowHeight,
          position: "fixed",
          top: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        },
      }}
    >
      <FadeSection>
        <h1>{title.data}</h1>

        {filters
          ? !showFilters &&
            WW < 1024 && (
              <button className="btn withIcon" onClick={() => handleMenu(true)}>
                {trad[lang].filter} <TuneIcon />
              </button>
            )
          : btnStatus.data === true && (
              <Button to={`/${btnUrl.data}`}>{btnTitle.data}</Button>
            )}

        {filters && (showFilters || WW >= 1024) && (
          <div className="filters">{generateFilters()}</div>
        )}
      </FadeSection>

      {showFilters && (
        <div className="buttons-container">
          <button className="btn thin" onClick={deleteFilters}>
            {trad[lang].remove_filters}
          </button>

          <button className="btn" onClick={() => handleMenu(false)}>
            {trad[lang].confirm}
          </button>
        </div>
      )}
    </motion.section>
  );
});
