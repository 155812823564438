import { useRef } from 'react';
import "./splitFlap.scss";
import { FlapDisplay } from 'react-split-flap-effect';
import { motion, useInView } from "framer-motion";


export const SplitFlap = (props) => {

    const { length = 3, value, padChar = "0", text } = props;

    const windowHeight = window.innerHeight;

    const viewportOptions = {
        margin: `0px 0px -${windowHeight / 2}px 0px`,
        once: true
    }

    const ref = useRef();
    const isInView = useInView(ref, viewportOptions);

    return (
        <motion.div
            className='split-flap-container'
            ref={ref}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={viewportOptions}
        >

            <FlapDisplay
                length={length}
                value={
                    isInView && value ?
                        value.toString()
                    : "0"
                }
                padChar={padChar}
                className={"split-flap"}
                timing={75}
            />

            {text &&
                <span className='split-flap-text'>{text}</span>
            }

        </motion.div>
    )
}