import "./ficheBureau.scss";
import { motion } from "framer-motion";
import { FADE_DURATION, FADE_OFFSET } from "../../helpers";


export const FicheBureau = (props) => {

    const { img, alt = "", title, className = "", text } = props;

    const viewportOptions = {
        margin: `0px 0px -${FADE_OFFSET}px 0px`,
        once: true
    }

    return (
        <motion.div
            className={`ficheBureau ${className}`}
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: FADE_DURATION }}
            viewport={viewportOptions}
        >

            <img src={img} alt={alt} className="main-img" />

            <em className="title">{title}</em>

            <p className="text">{text}</p>

        </motion.div>
    )
}