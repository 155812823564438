import { useContext, useEffect, useRef } from "react";
import { motion, useAnimationControls, useInView } from "framer-motion";
import { FADE_DURATION, FADE_OFFSET } from "../../helpers";
import { StoreContext } from "../../store/store";


export const FadeSection = (props) => {

    const { className = "", children, test } = props;

    const { pageData } = useContext(StoreContext);

    const viewportOptions = {
        margin: `0px 0px -${FADE_OFFSET}px 0px`
    }

    const controls = useAnimationControls();
    const motionRef = useRef();
    const isInView = useInView(motionRef, viewportOptions);

    useEffect(() => {

        controls.set({ opacity: 0 });
        
        if(isInView)
            controls.start({
                opacity: 1
            });

    }, [pageData])

    useEffect(() => {

        if(isInView)
            controls.start({
                opacity: 1
            });

    }, [isInView])

    return (
        <motion.div
            className={`section-container ${className}`}
            initial={{ opacity: 0 }}
            transition={{ duration: FADE_DURATION }}
            animate={controls}
            ref={motionRef}
        >

            {typeof children === "function" ?
                children({ isInView })
            :
                children
            }

        </motion.div>
    )
}