import "./searchInput.scss";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

export const SearchInput = (props) => {

    const {
        label,
        value,
        handleChange = () => {},
        dataKey,
        className
    } = props;

    const WW = window.innerWidth;

    return (
        <div className="search-input">

            <TextField
                label={label}
                variant="standard"
                value={value ?? ""}
                onChange={(e) => {
                    handleChange(dataKey, e.target.value);
                }}
                className={className}
                size="small"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <SearchIcon />
                        </InputAdornment>
                    )
                }}
                fullWidth
            />

        </div>
    )
}