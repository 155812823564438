import { forwardRef, useContext } from "react";
import "./partenaires.scss";
// import { ContactUs } from "../contactUs/ContactUs";
import { html } from "../../helpers";
import { StoreContext } from "../../store/store";
import { trad } from "../../utils/trad";
import { FadeSection } from "../../components/FadeSection/FadeSection";


export const Partenaires = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        content = {},
        partnersItem = {}
    } = section;
    const { data: items = [] } = partnersItem;

    const { lang } = useContext(StoreContext);

    return(
        <section
            className={`partenaires pageSection ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <div className="text">
                    
                    <h2>
                        <strong>{title.data}</strong>
                    </h2>

                    <div className="desc">
                        {html(content.data)}
                    </div>

                </div>

                <div className="grid">

                    {items.map(el => {

                        const { image = {} } = el[lang] ?? {};

                        return (
                            <div className="grid-item" key={el.id}>
                                <img src={image.data ? image.data.path : ""} alt={image.data ? image.data.alt : "not found"} className="img-white" />
                            </div>
                        )
                    })}

                    <div className="grid-item">
                        <p className="more">{trad[lang].many_more}</p>
                    </div>

                </div>

                {/* <ContactUs
                    text="Une question ? Un renseignement ? Un café ?"
                    btnText="Contactez-nous"
                /> */}

            </FadeSection>

        </section>
    )
})