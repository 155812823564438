import { forwardRef, useRef, useContext } from "react";
import "./superProjets.scss";
import { Link } from "react-router-dom";
import { Button } from "../../components/UI/Button/Button";
import { StoreContext } from "../../store/store";
import { FadeSection } from "../../components/FadeSection/FadeSection";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Grid } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/grid';

import arrowRight from "../../utils/img/arrow-right-black.svg";
import HOVER_SALES from "../../utils/img/HOVER_SALES.svg";

export const SuperProjets = forwardRef((props, ref) => {

    const { section, template, projets = [], bgColor } = props;
    const {
        title = {},
        subTitle = {},
        button = {}
    } = section;
    const { title: btnTitle = {}, status: btnStatus = {}, url: btnUrl = {} } = button;

    const { lang, pageData } = useContext(StoreContext);
    const { page } = pageData ?? {};
    const { entity = {} } = page ?? {};

    const prevRef = useRef();
    const nextRef = useRef();

    const WW = window.innerWidth;

    return(
        <section
            className={`pageSection superProjets ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <div className="projets-header">

                    <h2>
                        <strong>{title.data}</strong>
                        {subTitle.data && <>
                            <br/>
                            {subTitle.data}
                        </>}
                    </h2>

                    <div className="arrows-container">

                        <img ref={prevRef} src={arrowRight} alt="<" className="arrow prev" />
                        <img ref={nextRef} src={arrowRight} alt=">" className="arrow next" />

                    </div>

                </div>

                <Swiper
                    modules={[Navigation, Grid]}
                    onInit={(swiper) => {
                        swiper.params.navigation.prevEl = prevRef.current;
                        swiper.params.navigation.nextEl = nextRef.current;
                        swiper.navigation.init();
                        swiper.navigation.update();
                    }}
                    className="projets-slider"
                    allowTouchMove={WW <= 1024 ? true : false}
                    slidesPerView={2}
                    slidesPerGroup={2}
                    spaceBetween={10}
                    grid={{
                        rows: 2,
                        fill: "row"
                    }}
                    breakpoints={{
                        600: {
                            slidesPerView: 2,
                            grid: {
                                rows: 1
                            },
                            spaceBetween: 10,
                            slidesPerGroup: 2
                        },
                        769: {
                            slidesPerView: 3,
                            grid: {
                                rows: 1
                            },
                            spaceBetween: 20,
                            slidesPerGroup: 3
                        },
                        1024: {
                            slidesPerView: 4,
                            grid: {
                                rows: 1
                            },
                            spaceBetween: 30,
                            slidesPerGroup: 4
                        }
                    }}
                >

                    {projets.map(el => {

                        const {
                            title = {},
                            image = {}
                        } = el[lang] ?? {};
                        //const { data: entities = [] } = el[lang] ? el[lang]["spe-entity"] : {};
                        
                        const { infoPage = {} } = el;
                        const {
                            slug = {}
                        } = infoPage[lang] ?? {};

                        return (
                            <SwiperSlide key={el.id}>
                                <Link to={`/${lang}/${slug.data}/${title.slug}`}>
                                    <img
                                        src={image.data ? image.data.path : ""}
                                        alt={image.data ? image.data.alt : "not found"}
                                        style={{
                                            "--letter-mask": `url("${HOVER_SALES}")`,

                                        }}
                                    />
                                    <p>{title.data}</p>
                                </Link>
                            </SwiperSlide>
                        )

                    })}
                    
                </Swiper>

                {btnStatus.data === true &&
                    <Button to={`/${btnUrl.data}${entity && entity.id ? `?entity=${entity.id}` : ""}`}>
                        {btnTitle.data}
                    </Button>
                }

            </FadeSection>

        </section>
    )
})