import { useContext, useEffect } from "react";
import { motion, useAnimationControls } from "framer-motion";
import { StoreContext } from "../../store/store";


export const Tags = (props) => {

    const { isInView, children, i } = props;

    const { pageData } = useContext(StoreContext);

    const controls = useAnimationControls();

    useEffect(() => {

        controls.set({ opacity: 0, x: "200%" });

    }, [pageData])

    useEffect(() => {

        if(isInView)
            controls.start({ opacity: 1, x: 0 });

    }, [isInView])

    return(
        <motion.span
            className="tag"
            initial={{ opacity: 0, x: "200%" }}
            transition={{ duration: 0.6, ease: "easeOut", delay: .2*i }}
            animate={controls}
        >
            {children}
        </motion.span>
    )
}