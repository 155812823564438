import { forwardRef, useContext } from "react";
import "./articleRH.scss";
import { motion } from "framer-motion";
import { html, FADE_DURATION, FADE_OFFSET } from "../../helpers";
import { ArticleList } from "../articleList/ArticleList";
import { Helmet } from "react-helmet";
import { StoreContext } from "../../store/store";


export const ArticleRH = forwardRef((props, ref) => {

    const { section, template } = props;
    const {
        title = {},
        date = {},
        thematique = {},
        content = {}
    } = section;

    const { lang } = useContext(StoreContext);

    return(<>
        <section
            className={`pageSection articleRH ${template} bg-white`}
            ref={ref}
        >

            <Helmet htmlAttributes={{ lang: lang }}>
                <title>
                    {title.data} - Superprod
                </title>
                <meta name="description" content={content.data} />
            </Helmet>

            <motion.div
                className="section-container"
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: FADE_DURATION }}
                viewport={{ margin: `0px 0px -${FADE_OFFSET}px 0px`, once: true }}
            >

                <h1>
                    {title.data}
                </h1>

                <p className="infos">
                    {date.data}<br/>
                    {thematique.data}
                </p>

                <div className="main-content">

                    {html(content.data)}

                </div>

            </motion.div>
        </section>

        <ArticleList section={section} isSection />
    </>)
})