import { useState, createContext, useEffect } from "react";
import { GetUserInfo } from "./index.service";
import Cookies from "js-cookie";

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [lang, setLang] = useState(
    localStorage.getItem("currentLanguage")
      ? localStorage.getItem("currentLanguage")
      : navigator.language.toLocaleLowerCase().slice(0, 2) === "fr"
      ? "fr"
      : "en"
  );
  const [user, setUser] = useState();
  const [pageData, setPageData] = useState();
  const [menusData, setMenusData] = useState();
  const [sectionsDOM, setSectionsDOM] = useState([]);
  const [headerDOM, setHeaderDOM] = useState();
  const [filtersData, setFiltersData] = useState({});
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  useEffect(() => {
    const token = Cookies.get("token");
    const tokenExpiration = Cookies.get("tokenExpiration");

    if (token && tokenExpiration) {
        const expirationDate = new Date(tokenExpiration);
        if (expirationDate > new Date()) {
            setIsUserLoggedIn(true);
        } else {
            setIsUserLoggedIn(false);
            Cookies.remove("token");
            Cookies.remove("tokenExpiration");
        }
    }


    if (isUserLoggedIn) {
        GetUserInfo()
            .then((userInfo) => {
                if (userInfo.status !== 200) {
                    setIsUserLoggedIn(false);
                    Cookies.remove("token");
                    Cookies.remove("tokenExpiration");
                }
            })
            .catch((error) => {
                setIsUserLoggedIn(false);
                Cookies.remove("token");
                Cookies.remove("tokenExpiration");
            });
    }

}, [isUserLoggedIn]);

  return (
    <StoreContext.Provider
      value={{
        lang,
        setLang,
        user,
        setUser,
        pageData,
        setPageData,
        sectionsDOM,
        setSectionsDOM,
        headerDOM,
        setHeaderDOM,
        filtersData,
        setFiltersData,
        menusData,
        setMenusData,
        isUserLoggedIn,
        setIsUserLoggedIn,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
