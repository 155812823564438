import { forwardRef, useEffect } from "react";
import "./simpleText.scss";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

export const SimpleText = forwardRef((props, ref) => {

    const { section, template, bgColor } = props;
    const {
        title = {},
        content = {}
    } = section;


    return(
        <section
            className={`pageSection simpleText ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
        >

            <FadeSection>

                <h1>{title.data}</h1>

                <div className="main-content">

                    {html(content.data)}

                </div>

            </FadeSection>

        </section>
    )
})