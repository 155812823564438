import { forwardRef, useContext } from "react";
import "./heroGroup.scss";
import { StoreContext } from "../../store/store";
import { html } from "../../helpers";
import { FadeSection } from "../../components/FadeSection/FadeSection";

import arrow from "../../utils/img/arrow-right.svg";

export const HeroGroup = forwardRef((props, ref) => {

    const { section, idSection, template, bgColor } = props;
    const {
        background = {},
        content = {},
        image = {}
    } = section;

    const { pageData } = useContext(StoreContext);

    const { sectionPage = [] } = pageData ?? {};

    const scrollToNextSection = () => {
        
        const currentSection = sectionPage.find(el => el.idSection === idSection);
        const currentIndex = sectionPage.indexOf(currentSection);
        const nextSection = sectionPage[currentIndex + 1];

        if(nextSection) {
            const element = document.querySelector(`.${nextSection.template}`);

            if(element)
                element.scrollIntoView({
                    behavior: "smooth"
                });
        }
    }

    return(
        <section
            className={`pageSection heroGroup ${template} ${
                bgColor === "white" ? "bg-white"
                : bgColor === "blue" ? "bg-blue"
                : bgColor === "rose" ? "bg-pink"
                : ""
            }`}
            ref={ref}
            style={{
                backgroundImage: background.data ? `url(${background.data.path})` : undefined
            }}
        >

            <FadeSection test="infoGroup">

                <img src={image.data ? image.data.path : ""} alt={image.data ? image.data.alt : "not found"} className="logo-group img-white" />

                {content.data &&
                    <div className="desc">
                        {html(content.data)}
                    </div>
                }

                <img src={arrow} alt="↓" className="arrow" onClick={scrollToNextSection} />

            </FadeSection>


        </section>
    )
})