import "./selectCustom.scss";
import { FormControl, InputLabel, Select, MenuItem, SvgIcon } from "@mui/material";

const CustomIcon = (props) => {
    return (
        <SvgIcon {...props} fontSize="small">
            <path d="M0,74.525a8.845,8.845,0,0,1,7.8,7.8,8.845,8.845,0,0,1,7.8-7.8" transform="translate(0.166 -65)" fill="none" stroke="#fff" strokeWidth="3"/>
        </SvgIcon>
    )
}

export const SelectCustom = (props) => {

    const { label, value, handleChange = () => {}, options = [], dataKey, error } = props;

    return (
        <FormControl
            variant="standard"
            fullWidth
            size="small"
            error={error}
        >
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                onChange={(e) => handleChange(dataKey, e.target.value)}
                IconComponent={(props) => <CustomIcon {...props} />}
            >
                {options.map(el =>
                    <MenuItem
                        key={el.value}
                        value={el.value}
                    >
                        {el.label}
                    </MenuItem>
                )}
            </Select>
        </FormControl>
    )
}