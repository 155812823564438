import { forwardRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { FADE_DURATION } from "../../helpers";

const LinkRef = forwardRef((props, ref) => 
    <Link {...props} ref={ref}>
        {props.children}
    </Link>
)

const MotionLink = motion(LinkRef);

export const ArticleItem = (props) => {

    const {
        title = {},
        thematique = {}
    } = props;

    const { pathname } = useLocation();

    const windowHeight = window.innerHeight;

    const viewportOptions = {
        margin: `0px 0px -${(windowHeight/5)}px 0px`,
        once: true
    };

    return(
        <MotionLink
            className="article-item"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: FADE_DURATION }}
            viewport={viewportOptions}
            to={`${pathname}/${title.slug}`}
        >

            <strong className="title">{title.data}</strong>

            <p className="theme">
                {thematique.data}
            </p>

        </MotionLink>
    )
}